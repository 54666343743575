export class ParameterTest {

    Perform: boolean
    K: number
    key: string

    constructor(
        perform: boolean,
        k: number,
        key: string
    ) {
        this.Perform = perform;
        this.K = k;
        this.key = key;
    }
}
