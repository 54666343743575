export enum TrendStatisticType {
    Mean = 'Mean',
    StandardDeviation = 'Standard Deviation',
    MaximumValue = 'Maximum Value',
    MinimumValue = 'Minimum Value',
    CpK = 'CpK',
    Cp = 'Cp',
    Cpm = 'Cpm',
    PpK = 'PpK',
    Pp = 'Pp'
}