import { NotificationService } from './../services/notification.service';
import { NotificationType } from "./notification-type.enum";

export class Notification {
    
    message: string
    type: string
    dismissible: boolean

    private dismissTimeout: any
    private notificationService: NotificationService

    constructor(
        message: string,
        type: string,
        duration: number,
        dismissible: boolean,
        notificationService: NotificationService
    ) {
        this.message = message;
        this.type = type;
        this.dismissible = dismissible;
        this.notificationService = notificationService;

        if (duration) {
            this.dismissTimeout = window.setTimeout(() => { this.notificationService.dismiss(this) }, duration);
        }
    }
}