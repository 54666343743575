interface Number {
    inRange(start: Number, end: Number): Boolean
    between(start: Number, end: Number): Boolean
}

Number.prototype.inRange = function(start: Number, end: Number): Boolean {
    const _start = start || Number.MIN_SAFE_INTEGER;    
    const _end = end || Number.MAX_SAFE_INTEGER;    
    
    return _start <= this && this <= _end ? true : false;
}

Number.prototype.between = function (start: Number, end: Number): Boolean {
    const _start = start || Number.MIN_SAFE_INTEGER;
    const _end = end || Number.MAX_SAFE_INTEGER;

    return _start < this && this < _end ? true : false;
}