import { ReportType } from "./report-type.enum";

export class ReportDefaults {

    ID: string
    showSPCChart: boolean
    showRangeChart: boolean 
    showFailedTests: boolean 
    showCapabilityHistogram: boolean 
    showStatistics: boolean 
    
    constructor(
        ID = null,
        showSPCChart = false,
        showRangeChart = false,
        showFailedTests = false,
        showCapabilityHistogram = false,
        showStatistics = false
    ) {
        this.ID = ID;
        this.showSPCChart = showSPCChart;
        this.showRangeChart = showRangeChart;
        this.showFailedTests = showFailedTests;
        this.showCapabilityHistogram = showCapabilityHistogram;
        this.showStatistics = showStatistics;
    }
}