import { AuthService } from 'src/app/services/auth.service';
import { User } from './../../models/user.model';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AdminApiService } from 'src/app/services/admin.api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() user: User;

  showDropDown: Boolean = false;
  isAdmin: boolean = false;

  constructor(private authService: AuthService, private router: Router, private adminService: AdminApiService) { }
  
  ngOnInit() {
    this.adminService.getUser().subscribe(x=>{
      this.isAdmin = (x.access === "admin");
    })
  }

  // Close dropdown when clicking outside of target element
  @HostListener('document:click', ['$event.target'])
  clickOff(target) {
    if (this.showDropDown) this.toggleDropDown();
  }

  toggleDropDown() {
    event.stopPropagation();
    this.showDropDown = !this.showDropDown;
  }

  logout() {
    this.authService.Logout().then(() => {
      this.user.token = null;
      this.router.navigate(['/login']);
    });
  }
}
