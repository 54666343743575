export enum ReportType {
    None = 'none',

    // Charts
    CapabilityHistogram = 'capability-histogram',
    SPCChart = 'spc-chart',
    RangeChart = 'range-chart',

    // Statistics
    Statistics = 'statistics',
    FailedTests = 'failed-tests',
}