import {environment} from "../../environments/environment";

export let AppConstants:any  = {
    api: {
        baseUrl: environment.apiBaseUrl
    },
    storage: {
       sample: "___sample___"
    },
    version: require('../../../package.json').version
};
