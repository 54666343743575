export class UserProfile {
    ID: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    promo: string;
    company: string;
    workPhone: string;
    homePhone: string;
    workAddress: string;
    homeAddress: string;
}