import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-arrows',
  templateUrl: './nav-arrows.component.html',
  styleUrls: ['./nav-arrows.component.scss']
})
export class NavArrowsComponent implements OnInit {

  @Input() prevUrl: String;
  @Input() nextUrl: String;
  @Input() prevDisabled: Boolean;
  @Input() nextDisabled: Boolean;

  @Output() navPrev = new EventEmitter<any>();
  @Output() navNext = new EventEmitter<any>();

  constructor(private router: Router) { }

  ngOnInit() {
  }

  prev() {
    this.navPrev.emit();
    this.nav(!this.prevDisabled, this.prevUrl);
  }

  next() {
    this.navNext.emit();
    this.nav(!this.nextDisabled, this.nextUrl);
  }

  nav(allowNavigation: Boolean, url: String) {
    if (allowNavigation) this.router.navigate([url]);
  }
}
