import { Parameter } from "./parameter.model";
import { ParameterApiService } from "../services/parameter.api.service";
import { Field } from "./field.model";

export class ChartRequest {
    
    Columns: object[]
    Settings: object[]

    constructor(parameterApiService: ParameterApiService, field: Field, parameter: Parameter, data: any[], trySaveTrend: boolean = false) {
        const tests = parameterApiService.prepareTests(parameter.Tests);
        
        let usl = null;
        let lsl = null;

        if (parameter.Limit1 != null) {
            if (parameter.Upper) { usl = parameter.Limit1 }
            else { lsl = parameter.Limit1 }
        }

        if (parameter.Limit2 != null) {
            if (!parameter.Upper) { usl = parameter.Limit2 }
            else { lsl = parameter.Limit2 }
        }

        this.Columns = [{
            ID: parameter.ID,
            Name: `${parameter.Name}`,
            Type: 'numeric',
            Values: data
        }];

        this.Settings = [{
            ID: parameter.ID,
            SubgroupSize: parameter.SubGroupSize,
            MovingRangeObservations: 2, // default
            Trend: trySaveTrend && parameter.SaveTrend,
            ColumnName: field.name,
            CapabilityParameters: {
                USL: usl,
                Target: parameter.Target != null ? Number(parameter.Target) : null,
                LSL: lsl
            },
            Tests: {
                SPCChart: tests,
                RangeChart: {
                    Test1: tests.Test1,
                    Test2: tests.Test2,
                    Test3: tests.Test3,
                    Test4: tests.Test4
                },
            },
        }];
    }
}