import { ParameterTests } from "./parameter-tests.model";


export class Parameter {
     
     ID: string;
     Name: string;
     Description: string;
     MachineName: string;
     Units: string;
     Limit1: number;
     Upper: boolean;
     Limit2: number;
     Target: number;
     Tests: ParameterTests;
     SubGroupSize: number;
     SaveTrend: boolean;
     Active: boolean;

     constructor(
          ID = null,
          Name = "",
          Description = "",
          Units = "",
          MachineName = "",
          Limit1 = null,
          Upper = true,
          Limit2 = null,
          Target = null,
          Tests = new ParameterTests(),
          SubGroupSize = 1,
          SaveTrend = true,
          Active = true
     ) {
          this.ID = ID;
          this.Name = Name;
          this.Description = Description;
          this.MachineName = MachineName;
          this.Units = Units;
          this.Limit1 = Limit1;
          this.Upper = Upper;
          this.Limit2 = Limit2;
          this.Target = Target;
          this.Tests = Tests;
          this.SubGroupSize = SubGroupSize;
          this.SaveTrend = SaveTrend;
          this.Active = Active
     }
}
