import { NotificationType } from './../../models/notification-type.enum';
import { TrendService } from './../../services/trend.service';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { Trend } from 'src/app/models/trend.model';
import { ParameterApiService } from 'src/app/services/parameter.api.service';
import { LateralService } from 'src/app/services/lateral.service';
import { ParameterTrendPair } from 'src/app/models/parameter-trend-pair.model';

@Component({
  selector: 'app-key-trend-settings',
  templateUrl: './key-trend-settings.component.html',
  styleUrls: ['./key-trend-settings.component.scss']
})
export class KeyTrendSettingsComponent implements OnInit {

  public parameterTrendPairs: ParameterTrendPair[] = [];

  constructor(
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private parameterService: ParameterApiService,
    private trendService: TrendService,
    private lateralService: LateralService
  ) { }

  ngOnInit() {
    this.loadingService.startLoading('Loading Parameters');
    this.parameterService.getAPIParameters().subscribe(
      parameters => {
        this.lateralService.parameters = parameters

        this.loadingService.startLoading('Loading Trends');
        this.trendService.getAllParameterTrends().subscribe(
          (parameterTrends) => {
            this.parameterTrendPairs = [];

            parameterTrends.forEach(paramTrend => {
              const parameterTrendPairs = paramTrend.Trends.map(trend => {
                const foundTrend = new Trend(
                  paramTrend.ID,
                  trend['Cp'],
                  trend['Cpk'],
                  trend['Cpm'],
                  trend['Maximum Value'],
                  trend['Mean'],
                  trend['Minimum Value'],
                  trend['Pp'],
                  trend['Ppk'],
                  trend['Specifications']['LSL'],
                  trend['Specifications']['Target'],
                  trend['Specifications']['USL'],
                  trend['Standard Deviation'],
                  trend['Timestamp'],
                  trend['Epoch'],
                  trend['Data Name']
                );

                const foundParam = this.lateralService.parameters.find(param => param.ID == paramTrend.ID);

                return new ParameterTrendPair(foundParam, foundTrend);
              });

              this.parameterTrendPairs = this.parameterTrendPairs.concat(parameterTrendPairs);
            })

            this.parameterTrendPairs.sort((a, b) => {
              // Note must coerce dates to number in order to subtract due to typescript rules
              return +new Date(b.trend.timestamp) - +new Date(a.trend.timestamp);
            });

            this.parameterTrendPairs = this.parameterTrendPairs.filter((trendPair) => trendPair.parameter.Active)
          },
          (error) => {
            this.loadingService.stopLoading();
            this.notificationService.notify(error, NotificationType.DANGER)
          },
          () => {
            this.loadingService.stopLoading();
          }
        )
      },
      error => {
        this.loadingService.stopLoading();
        this.notificationService.notify(error, NotificationType.DANGER);
      }
    )
  }

  remove(idx: number) {
    this.confirmationService.confirm('You are about to delete this data from Key Trends. Are you sure?')
      .then(() => {
        this.loadingService.startLoading("Deleting trend");

        const trend = this.parameterTrendPairs[idx].trend;

        this.trendService.deleteTrend(trend).subscribe(
          () => {
            this.parameterTrendPairs.splice(idx, 1);
            this.notificationService.notify("Trend successfully deleted.", NotificationType.SUCCESS);
          },
          error => {
            this.loadingService.stopLoading();
            this.notificationService.notify(error, NotificationType.DANGER);
          },
          () => {
            this.loadingService.stopLoading();
          }
        );
      });
  }
}
