import { Injectable } from '@angular/core';

@Injectable()
export class ConfirmationService {

  public showConfirmation: boolean = false;
  public body: string;
  public header: string;
  public resolveMessage: string;
  public rejectMessage: string;
  public resolveBtnClass: string;
  public rejectBtnClass: string;
  
  private promise: Promise<string>;
  private executor: Executor

  constructor() { }

  confirm(
    body: string = '',
    header: string = 'Are you sure?',
    resolveMessage: string = 'Confirm',
    rejectMessage: string = 'Cancel',
    resolveBtnClass: string = 'is-primary',
    rejectBtnClass: string = ''
  ): Promise<string> {
    this.showConfirmation = true;
    
    this.body = body;
    this.header = header;
    this.resolveMessage = resolveMessage;
    this.rejectMessage = rejectMessage;
    this.resolveBtnClass = resolveBtnClass;
    this.rejectBtnClass = rejectBtnClass;

    this.promise = new Promise<string>((resolve, reject) => {
      this.executor = new Executor(
        () => {
          resolve();
        }, 
        (reason: string) => {
          reject(reason);
        }
      );
    });

    return this.promise;
  }

  resolveConfirmation() {
    this.showConfirmation = false;

    if (this.executor) {
      this.executor.resolve();
    }
  }

  rejectConfirmation(reason: string) {
    this.showConfirmation = false;
    
    if (this.executor) {
      this.executor.reject(reason);
    }
  }
}

class Executor {

  private onResolve: Function
  private onReject: Function

  constructor(
    onResolve: Function = () => {}, 
    onReject: Function= (reason: string) => {}
  ) {
    this.onResolve = onResolve;
    this.onReject = onReject;
  }
  
  resolve() {
    this.onResolve();
  }

  reject(reason: string) {
    this.onReject(reason);
  }
}
