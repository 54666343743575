import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationType } from 'src/app/models/notification-type.enum';
import { UserProfile } from 'src/app/models/profile.model';
import { ProfileApiService } from 'src/app/services/profile.api.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(private authService: AuthService, private notificationService: NotificationService, private profileService: ProfileApiService) { }

  profile: UserProfile;
  revertProfile: UserProfile;
  provider: any;
  ngOnInit() {
    this.getUserProfile();   
  }
  resetPassword(){
    this.authService.forgotPassword(this.profile.email).then(res=>{     
      this.notificationService.notify("Check your email for a link to reset your password.", NotificationType.INFO);
     });
  }
  getUserProfile(){
    this.profileService.getProfile().subscribe(profile=>{
     // debugger;
      if(profile){
        this.profile = profile;
        this.revertProfile = profile;
        this.authService.fetchSignInMethodsForEmail(profile.email).then(result=>{
          this.provider = result[0];
        })
      }
      else{
        this.profile = new UserProfile();
      }
    })
  }
  setUserProfile(){
    this.profileService.setProfile(this.profile).subscribe(profile=>{
      this.notificationService.notify("Profile Saved", NotificationType.SUCCESS);
      this.revertProfile = this.profile;
    })
  }

  cancel(){
    this.profile = this.revertProfile;
  }

}
