import { LoadingService } from '../../services/loading.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ParameterApiService } from '../../services/parameter.api.service';
import { NotificationService } from '../../services/notification.service';
import { NotificationType } from '../../models/notification-type.enum';
import { ConfirmationService } from '../../services/confirmation.service';
import { Parameter } from 'src/app/models/parameter.model';
import { ParameterTests } from 'src/app/models/parameter-tests.model';

@Component({
  selector: 'app-params',
  templateUrl: './params.component.html',
  styleUrls: ['./params.component.scss']
})
export class ParamsComponent implements OnInit {
  parameters: any[];
  showModal: boolean;
  paramBeingEdited: number;
  showConfirm: boolean;
  upper:boolean = true;
  newParameter: Parameter;

  public confirmDeletePromise: Promise<string>

  @Input() isNewOnly:boolean;
  @Output() close = new EventEmitter<boolean>();

  constructor(
    private parameterService: ParameterApiService,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.getParameters();
    if(this.isNewOnly){
      this.addParameter();
    }
  }
  getParameters(){
    this.loadingService.startLoading('Loading Spec Limits');
    this.parameterService.getAPIParameters().subscribe(
      parameters =>{
        this.parameters = parameters;
      },
      (error) => {
        this.loadingService.stopLoading();
        this.notificationService.notify(error, NotificationType.DANGER);
      },
      () => {
        this.loadingService.stopLoading();
      }
    )
  }
  initNewParameter(){
    this.newParameter = new Parameter();
    
  }
  activateModal(){
    this.showModal = true;
  }
  deactivateModal(){
    this.close.emit(true);
    this.showModal = false;
    this.showConfirm = false;  
    

  }
  editParameter(parameterIndex: number){
    this.newParameter = new Parameter();
    this.newParameter = JSON.parse(JSON.stringify(this.parameters[parameterIndex]));
    this.activateModal();
  }
  addParameter(){  
    //this.paramBeingEdited = this.parameters.push(new Parameter()) - 1;
    
    /* let newParameter = {
      ID: null,
      Product: "", 
      Part: "",
      Dimension: "",
      Units: "",
      UpperSpec: null,
      LowerSpec: null,
      Target: null,
      SubGroupSize: 1,
      Tests: new ParameterTests(),
      SaveTrend: true
  }; */
    
    //this.paramBeingEdited = this.parameters.push(newParameter) - 1;
    this.initNewParameter();

    this.activateModal();
  }
  saveParameters(){
    if(this.newParameter.ID){
      //this is an edit
      // find index of the parameter and update it
      
      this.parameters[this.parameters.findIndex(x=>x.ID === this.newParameter.ID)] = this.newParameter;
      this.initNewParameter();
    }
    else{
      this.parameters.push(this.newParameter);

    }
    this.loadingService.startLoading('Saving Spec Limits')
    this.parameterService.setAPIParameters(this.parameters).subscribe(
      parameters => {
       this.notificationService.notify('Spec Limits Saved', NotificationType.SUCCESS);
       //close the modal
    this.deactivateModal();
      },
      (error) => {
        //reload the local list
        this.getParameters();
        this.loadingService.stopLoading();
        this.notificationService.notify(error, NotificationType.DANGER);
        //close the modal
    this.deactivateModal();
      },
      () => {
        this.loadingService.stopLoading();
        //close the modal
    this.deactivateModal();
      }
    )
    
    
  }
  
  confirmDelete(index:number) {

    this.confirmDeletePromise = this.confirmationService.confirm(
      'You are about to delete a spec limit.  Trends and trend settings associated with this spec limit will be lost. Are you sure?',
      'Delete Spec Limit?',
      'Delete',
      'Cancel',
      'is-danger'
    );

    this.confirmDeletePromise.then(() => {
        this.deleteParameter(index);
      }, 
      () => {}
    );
  }
  
  deleteParameter(i){
    this.parameters[i].Active = false;
    this.parameterService.setAPIParameters(this.parameters).subscribe(
      parameters => {
       this.notificationService.notify('Spec Limits Saved', NotificationType.SUCCESS);
      },
      (error) => {
        //reload the local list
        this.getParameters();
        this.loadingService.stopLoading();
        this.notificationService.notify(error, NotificationType.DANGER);
      },
      () => {
        this.loadingService.stopLoading();
      }
    )
    
    //close the modal
    this.deactivateModal();
  }

  showAdvanced:boolean;
  toggleAdvanced(){
    this.showAdvanced = !this.showAdvanced;
  }

  toggleUpper(value:boolean){
    this.newParameter.Upper = value;
  }
  validate(){
    //debugger;
    //innocent until proven guilty
    let valid = true;
    let validationMessage = "Please correct the following issues: ";

    if(!this.newParameter.Name){
      valid = false;
      validationMessage+= "  -Enter a Value for Spec Limit Name  ";
    }

    if(this.newParameter.Limit1 === null || this.newParameter.Limit1 === undefined){
      valid = false;
      validationMessage+= " -Enter a Value for Limit 1 ";
    }

    if(!this.newParameter.SubGroupSize){
      valid = false;
      validationMessage+= " -Enter a Value for Subgroup Size ";
    }

    if(this.newParameter.Limit1 === this.newParameter.Limit2){
      valid = false;
      validationMessage+= " -Limit 1 cannot equal Limit 2 ";
    }
    //Validate limits and target so that they make sense

    if(this.newParameter.Upper){
      if((this.newParameter.Target !== null && this.newParameter.Target !== undefined) && this.newParameter.Limit1 < this.newParameter.Target){
        valid = false;
        validationMessage+= " - Limit 1 is an upper limit, so it must be greater than the target.";
      }
      if(this.newParameter.Limit2 !== null && this.newParameter.Limit2 !== undefined){
        if(this.newParameter.Limit1 < this.newParameter.Limit2){
          valid = false;
          validationMessage+= " - Limit 1 is an upper limit, so it should be greater than Limit 2."
        }
        if((this.newParameter.Target !== null && this.newParameter.Target !== undefined) && this.newParameter.Limit2 > this.newParameter.Target){
          valid = false;
          validationMessage+= " - Limit 2 is a lower limit, so it must be less than the target."
        } 
      }
    }
    else{ //Upper is false so lim 1 is lower limit
      if((this.newParameter.Target !== null && this.newParameter.Target !== undefined) && this.newParameter.Limit1 > this.newParameter.Target){
        valid = false;
        validationMessage+= " - Limit 1 is an lower limit, so it must be less than the target.";
      }
      if(this.newParameter.Limit2 !== null && this.newParameter.Limit2 !== undefined){
        if(this.newParameter.Limit2 < this.newParameter.Target){
          valid = false;
          validationMessage+= " - Limit 2 is an upper limit, so it must be greater than the target."
        }
        if(this.newParameter.Limit1 > this.newParameter.Limit2){
          valid = false;
          validationMessage+= " - Limit 1 is a lower limit, so it should be less than Limit 2."
        } 
      }
    }
    
    
    if(valid){
      this.saveParameters();
    }
    else{
      this.notificationService.notify(validationMessage, NotificationType.DANGER, 3000, true);
    }
  }
}
