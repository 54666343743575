import { ParameterTests } from './../models/parameter-tests.model';
import { ParameterTest } from './../models/parameter-test.model';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Parameter } from '../models/parameter.model';
import { identifierModuleUrl } from '@angular/compiler';
import { AuthService } from './auth.service';
import * as firebase from 'firebase/app';
import { UserProfile } from '../models/profile.model';
import { ProfileApiService } from './profile.api.service';
import { environment } from 'src/environments/environment';
import { ReportDefaults } from '../models/report-defaults.model';
import { ReportDefaultsService } from './report-defaults.service';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
@Injectable()
export class AdminApiService extends ApiBaseService {

    constructor(protected http: HttpClient, protected authService: AuthService, private profileService: ProfileApiService, private defaultsService: ReportDefaultsService, public router: Router) {
        super(http, authService);
    }
          
    getAllUsers(): Observable<any> {             
        return this.http.get<any>(this.getUrl(`/authorized_users`), this.manufactingApiOptions());
    }
    setUser(user:any, token?: any): Observable<any> {             
        return this.http.put<any>(this.getUrl(`/gotrendable/user`), user, this.manufactingApiOptions(token, true));
    }
    getUser(): Observable<any> {             
        return this.http.get<any>(this.getUrl(`/user`), this.manufactingApiOptions());
    }
    createUser(name, email, password, isAdmin, fromLogin) {
        
        return new Promise((resolve, reject) => {
            let newUser = {
                email: email,
                name: name,
                uuid: null,
                access: (isAdmin)?"admin":"user"
            }
            //put user to db
            this.setUser(newUser).subscribe(x=>{
                
                var secondaryApp = firebase.initializeApp(environment.firebase, "Secondary");
            
                secondaryApp.auth().createUserWithEmailAndPassword(email, password).then((res:any) => {
                    //at this stage I am logged in as the new user!  I should set up defaults and things here.
                    let secondToken = res.user.qa;
                    //initial profile
                    let newProfile = new UserProfile();
                    newProfile.ID = null;
                    newProfile.email = email;
                    newProfile.name = name;
                    this.profileService.setProfile(newProfile, secondToken).subscribe();
                    //initial report defaults
                    let reportDefaults = new ReportDefaults();
                    reportDefaults.ID = null;
                    reportDefaults.showCapabilityHistogram = true;
                    reportDefaults.showFailedTests = false;
                    reportDefaults.showRangeChart = false;
                    reportDefaults.showSPCChart = true;
                    reportDefaults.showStatistics = true; 
                    this.defaultsService.setDefaults(reportDefaults, secondToken).subscribe();
                    
                    if(!fromLogin){
                        //I don't know if the next statement is necessary 
                        secondaryApp.auth().signOut();
                        secondaryApp.delete();
                    }
                    resolve(res);
                })
                .catch(function (error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    if (errorCode == 'auth/weak-password') {
                    alert('The password is too weak.');
                    } else {
                    alert(errorMessage);
                    }
                    console.log(error);
                });
            });   
            
            
            });
      }
      
      createUserAnon(firstName, lastName, company, email, promo, password, isAdmin, fromLogin) {
        
        return new Promise((resolve, reject) => {
            let startDate = new Date();
            let endDate = new Date();
            endDate.setDate(endDate.getDate() + 8);
            let startString = startDate.getFullYear().toString() + '-' + (startDate.getMonth() +1).toString() + '-' + startDate.getDate().toString();
            let endString = endDate.getFullYear().toString() + '-' + (endDate.getMonth() +1).toString() + '-' + endDate.getDate().toString();
            let newUser = {
                email: email,
                name: firstName + ' ' + lastName ,
                license: {
                    trial: {start: startString, end: endString},
                    type: "trial"
                },
                uuid: null,
                access: (isAdmin) ? "admin":"user"
            }
            
                
                //var secondaryApp = firebase.initializeApp(environment.firebase, "Secondary");
            
            firebase.auth().createUserWithEmailAndPassword(email, password).then((res:any) => {
                
                this.authService.currentUser = new User(
                    name,
                    email,
                    null,
                    res.user.qa
                  );

                    //at this stage I am logged in as the new user!  I should set up defaults and things here.
                    //put user to db
                    //let secondToken = res.user.qa;
            this.setUser(newUser).subscribe(x=>{
                
                    //initial profile
                    let newProfile = new UserProfile();
                    newProfile.ID = null;
                    newProfile.email = email;
                    newProfile.name = name;
                    newProfile.company = company;
                    newProfile.firstName = firstName;
                    newProfile.lastName = lastName;
                    newProfile.promo = promo;
                    this.profileService.setProfile(newProfile).subscribe();
                    //initial report defaults
                    let reportDefaults = new ReportDefaults();
                    reportDefaults.ID = null;
                    reportDefaults.showCapabilityHistogram = true;
                    reportDefaults.showFailedTests = false;
                    reportDefaults.showRangeChart = false;
                    reportDefaults.showSPCChart = true;
                    reportDefaults.showStatistics = true; 
                    this.defaultsService.setDefaults(reportDefaults).subscribe();
                    
                    
                    resolve(res);
                    });
                })
                .catch(function (error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    if (errorCode == 'auth/weak-password') {
                    alert('The password is too weak.');
                    } else {
                    alert(errorMessage);
                    }
                    console.log(error);
                });
            });   
            
      }
}