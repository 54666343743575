import { Injectable } from '@angular/core';

@Injectable()
export class LoadingService {

  public isLoading: boolean = false;
  public message: string = 'Loading'

  constructor() { }

  startLoading(message: string = 'Loading') { 
    this.isLoading = true;
    this.message = message;
  }

  stopLoading() {
    this.isLoading = false;
  }
}
