import { NotificationType } from '../../models/notification-type.enum';
import { Component, OnInit } from '@angular/core';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
//import {Papa} from 'papaparse/papaparse.js';
import { PapaParseService } from 'ngx-papaparse';
import { LateralService } from '../../services/lateral.service';
import { LoadingService } from '../../services/loading.service';
import { NotificationService } from '../../services/notification.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  public files: UploadFile[] = [];
  
  isSuccessful:boolean = false;
  isFailed:boolean = false;
  fields: any[];
  showData: boolean = false;
  filename:string = "";
  browseButtonText:string = "Browse..."
  showImage:boolean;

  get isAnalyzing() {
    return this.loadingService.isLoading;
  }

  constructor(
    private papa: PapaParseService, 
    private lateralService: LateralService,
    private loadingService: LoadingService,
    private notificationService: NotificationService,
    //private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // The upload step indicates a new analysis, therefore clear any reports
    this.lateralService.reports = [];
    this.filename = this.lateralService.fileName
    if(this.filename){
      this.isSuccessful = true;
    }
  }
  //library documentation: https://github.com/georgipeltekov/ngx-file-drop

  public dropped(event: UploadEvent) {
  
    if(event.files.length === 1){
      //this.loadingService.startLoading('Analyzing file');
      const droppedFile = event.files[0];

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          //debugger;
          var name = file.name;
          this.filename = name;
          this.lateralService.fileName = this.filename;
          var type = name.substr(name.lastIndexOf('.') + 1);
          if(type == 'csv'){
            //debugger;
            this.parseCsv(file);
          }
          else if(type === 'xlsx' || type === 'xls'){
            this.parseXlsx(file);
          }       
        });
      } 
      else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }

    }
    else{
      this.notificationService.notify('Only single file parsing supported', NotificationType.DANGER);
    }     
  }

  fileFromBrowse(event){
    //this.loadingService.startLoading('Analyzing file');
    var name = event.files[0].name;
    this.filename = name;
    this.lateralService.fileName = this.filename;
    var type = name.substr(name.lastIndexOf('.') + 1);
 
    if(type == 'csv'){
      //debugger;
      this.parseCsv(event.files[0]);
    }
    else if(type === 'xlsx' || type === 'xls'){
      this.parseXlsx(event);
    }
    //this.parseCsv(event.files[0]);
  }

  parseCsv(file){
     //TODO: UPLOAD LOGIC
     this.papa.parse(
      file, 
      {
      complete: results => {
        //debugger;
        console.log(results);      
                    
        this.lateralService.data = this.trim(results.data);
        this.fields = [];
        let count = 0;
        for(let field of results.meta.fields){
          let item = {
            id: count,
            name: field,
            length: this.analyzeFieldLength(results.data, field), 
            type: this.analyzeFieldType(results.data, field),
            mappedParameter: null
          }
          this.fields.push(item);
          count++;
        }
        this.lateralService.fields = this.fields;
        this.loadingService.stopLoading();
        this.isSuccessful = true; 
        //this.cdRef.detectChanges();
      },
      header: true,
      dynamicTyping: true
    });
    
  }
  
  reset(){
   this.loadingService.stopLoading();
   this.isSuccessful= false;
   this.isFailed = false;
   this.lateralService.data = [];
   this.fields = [];
   this.browseButtonText = "Browse...";
  this.showData = false;
  }

  trim(csvs: any[]) : any[]{   
    if(csvs[csvs.length-1].length != csvs[csvs.length-2]){
      //the last field is inproperly formatted, remove it
      let removedElement = csvs.pop();
    }
    return csvs;
  }

  analyzeFieldLength(array: any[], fieldName: string): number{
    let count  = 0;
    for (let item of array){
      if(item[fieldName] && item[fieldName] !=" "){
        count++;
      }
    }
    return count;
  }
  analyzeFieldType(array: any[], fieldName: string): string{
    let type = "";

    try{
      type = typeof array[0][fieldName];      
    }
    catch{
      type = "Unknown"
    }
    return type;


  }
  
  
 
  parseXlsx(file: any) {
  // wire up file reader 
//var fileContainer :any = {files:[file]};
  const target: DataTransfer = <DataTransfer>(file);
  
  const reader: FileReader = new FileReader();
  reader.onload = (e: any) => {
    // read workbook
    const bstr: string = e.target.result;
    const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

    // grab first sheet
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    // save data
    let data = XLSX.utils.sheet_to_json(ws, {header: 1});
    this.lateralService.data = this.pivotAndExtractFields(data);
    this.loadingService.stopLoading();
    this.browseButtonText = "Select a different file..."
    this.isSuccessful = true; 
    //this.cdRef.detectChanges();
    
  };
  //reader.readAsBinaryString(fileContainer.files[0]);
  var actualFileTarget = (target.files ? target.files[0] : target);
  var blob =  actualFileTarget as Blob;
  reader.readAsBinaryString(blob);
}
pivotAndExtractFields(flatData): any[]{

  let fields = flatData.splice(0, 1);
  let dynamicData = [];
  
  flatData.forEach((item) => {
    let newItem = {};
    fields[0].forEach((field, fieldIndex)=>{

      console.log(item[fieldIndex]);
      newItem[String(field)]= item[fieldIndex];
    })
    dynamicData.push(newItem);
  });

  let newFields = [];
  let count = 0;
  for(let field of fields[0]){
    field = String(field);
    console.log(field);
    let item = {
      id: count,
      name: String(field),
      length: this.analyzeFieldLength(dynamicData, field), 
      type: this.analyzeFieldType(dynamicData, field),
      mappedParameter: null
    }
    newFields.push(item);
    count++;
  }
  this.lateralService.fields = newFields;
  

 return dynamicData;
  
}
toggleImage(show:boolean){
  this.showImage = show;
}
}
