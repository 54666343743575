import { NotificationService } from '../../services/notification.service';
import { Component, OnInit } from '@angular/core';
import { Notification } from '../../models/notification.model';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  get notifications() {
    return this.notificationService.notifications;
  }

  constructor(private notificationService: NotificationService) { }

  ngOnInit() {
  }

  dismiss(notification: Notification) {
    this.notificationService.dismiss(notification);
  }
}
