import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading-panel',
  templateUrl: './loading-panel.component.html',
  styleUrls: ['./loading-panel.component.scss']
})
export class LoadingPanelComponent implements OnInit {

  // Not doing this for the alpha, but consider using this component 
  // for each report component. They load independently and we could display that.
  // To achieve this we could emit events from each report type and displaying loading 
  // in the report panel
  _isLoading:boolean;
  @Input() 
  set isLoading (value){
    this._isLoading = value;
    //this.cdRef.detectChanges();
  }
  @Input() message: string;

  constructor() { }

  ngOnInit() {
  }

}
