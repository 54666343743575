import { TrendReport } from './trend-report-model';
export class TrendSettings {

    ID: string
    trendReports: TrendReport[];

    constructor(
        ID: string = null,
        trendReports: TrendReport[] = []
    ) {
        this.ID = ID;
        this.trendReports = trendReports;
    }
}