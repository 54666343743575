export class FailedTestPoint {

    index: number;
    value: any;
    outOfSpec: boolean = false;

    constructor(index: number, value: any, lowerSpec: any, upperSpec: any) {
        this.index = index;
        this.value = value;

        // If below lower spec, then point is out of spec
        if ((lowerSpec || lowerSpec == 0) && value < lowerSpec) this.outOfSpec = true;

        // If above upper spec, then point is out of spec
        if ((upperSpec || upperSpec == 0) && value > upperSpec) this.outOfSpec = true;
    }
}