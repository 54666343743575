import { ParameterTests } from "./parameter-tests.model";

export class RangeReport {

    lowerControlLimits: number[]
    MRBar: number
    upperControlLimits: number[]
    movingRange: number[]
    failedTest1: number[]
    failedTest2: number[]
    failedTest3: number[]
    failedTest4: number[]

    performedTest1: boolean = false;
    performedTest2: boolean = false;
    performedTest3: boolean = false;
    performedTest4: boolean = false;

    constructor(response: object, parameterTests: ParameterTests) {
        const mr = response['MRChart'] || response['RChart'] || response['SChart'];

        const tests = mr['Tests']

        this.lowerControlLimits = mr['LCLValues'];
        this.MRBar = mr['Centerline'];
        this.upperControlLimits = mr['UCLValues'];
        this.movingRange = mr['Values'];
        this.failedTest1 = tests['Test1']['Test Failed at points'];
        this.failedTest2 = tests['Test2']['Test Failed at points'];
        this.failedTest3 = tests['Test3']['Test Failed at points'];
        this.failedTest4 = tests['Test4']['Test Failed at points'];

        this.performedTest1 = parameterTests.Test1.Perform;
        this.performedTest2 = parameterTests.Test2.Perform;
        this.performedTest3 = parameterTests.Test3.Perform;
        this.performedTest4 = parameterTests.Test4.Perform;
    }
}