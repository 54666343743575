import { DataTools } from '../utils/data-tools';

export class CapabilityReport {

    // Process Data
    min: number
    max: number
    lowerSpec: number
    target: number
    upperSpec: number
    mean: number
    n: number
    stdDev: number
    stdDevWithin: number

    // Overall Capability
    pp: number
    ppl: number
    ppu: number
    ppk: number
    cpm: number

    // Potential Capability
    cp: number
    cpl: number
    cpu: number
    cpk: number
    
    // Observed Performance
    observedPpmLessThanLowerSpec: number
    observedPpmGreaterThanUpperSpec: number
    observedPpmTotal: number

    // Expected Overall Performance
    expectedOverallPpmLessThanLowerSpec: number
    expectedOverallPpmGreaterThanUpperSpec: number
    expectedOverallPpmTotal: number

    // Expected Within Performance
    expectedWithinPpmLessThanLowerSpec: number
    expectedWithinPpmGreaterThanUpperSpec: number
    expectedWithinPpmTotal: number

    constructor(response: object) {
        const capability = response['Capability(normal)'];
        
        const processData = capability['Process Data'];
        const overallCapability = capability['Overall Capability'];
        const potentialCapability = capability['Potential (Within) Capability'];
        const observedPerformance = capability['Performance']['Observed'];
        const expectedOverallPerformance = capability['Performance']['Expected Overall'];
        const expectedWithinPerformance = capability['Performance']['Expected Within'];

        // Process Data
        this.min = processData['Minimum'] !== null ? Number(processData['Minimum']) : null;
        this.max = processData['Maximum'] !== null ? Number(processData['Maximum']) : null;
        this.lowerSpec = processData['LSL'] !== null ? Number(processData['LSL']) : null;
        this.target = processData['Target'] !== null ? Number(processData['Target']) : null;
        this.upperSpec = processData['USL'] !== null ? Number(processData['USL']) : null;
        this.mean = processData['Sample Mean'] !== null ? Number(processData['Sample Mean']) : null;
        this.n = processData['Sample N'] !== null ? Number(processData['Sample N']) : null;
        this.stdDev = processData['StDev(Overall)'] !== null ? Number(processData['StDev(Overall)']) : null;
        this.stdDevWithin = processData['StDev(Within)'] !== null ? Number(processData['StDev(Within)']) : null;

        // Overall Capability
        this.pp = overallCapability['Pp'] !== null ? Number(overallCapability['Pp']) : null;
        this.ppl = overallCapability['PPL'] !== null ? Number(overallCapability['PPL']) : null;
        this.ppu = overallCapability['PPU'] !== null ? Number(overallCapability['PPU']) : null;
        this.ppk = overallCapability['Ppk'] !== null ? Number(overallCapability['Ppk']) : null;
        this.cpm = overallCapability['Cpm'] !== null ? Number(overallCapability['Cpm']) : null;

        // Potential Capability
        this.cp = potentialCapability['Cp'] !== null ? Number(potentialCapability['Cp']) : null;
        this.cpl = potentialCapability['CPL'] !== null ? Number(potentialCapability['CPL']) : null;
        this.cpu = potentialCapability['CPU'] !== null ? Number(potentialCapability['CPU']) : null;
        this.cpk = potentialCapability['Cpk'] !== null ? Number(potentialCapability['Cpk']) : null;
        
        // Observed Performance
        this.observedPpmLessThanLowerSpec = observedPerformance['PPM < LSL'] !== null ? Number(observedPerformance['PPM < LSL']) : null;
        this.observedPpmGreaterThanUpperSpec = observedPerformance['PPM > USL'] !== null ? Number(observedPerformance['PPM > USL']) : null;
        this.observedPpmTotal = observedPerformance['PPM Total'] !== null ? Number(observedPerformance['PPM Total']) : null;

        // Expected Overall Performance
        this.expectedOverallPpmLessThanLowerSpec = expectedOverallPerformance['PPM < LSL'] !== null ? Number(expectedOverallPerformance['PPM < LSL']) : null;
        this.expectedOverallPpmGreaterThanUpperSpec = expectedOverallPerformance['PPM > USL'] !== null ? Number(expectedOverallPerformance['PPM > USL']) : null;
        this.expectedOverallPpmTotal = expectedOverallPerformance['PPM Total'] !== null ? Number(expectedOverallPerformance['PPM Total']) : null;

        // Expected Within Performance
        this.expectedWithinPpmLessThanLowerSpec = expectedWithinPerformance['PPM < LSL'] !== null ? Number(expectedWithinPerformance['PPM < LSL']) : null;
        this.expectedWithinPpmGreaterThanUpperSpec = expectedWithinPerformance['PPM > USL'] !== null ? Number(expectedWithinPerformance['PPM > USL']) : null;
        this.expectedWithinPpmTotal = expectedWithinPerformance['PPM Total'] !== null ? Number(expectedWithinPerformance['PPM Total']) : null;
    }
}