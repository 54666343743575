export class User {
    
    name: string;
    email: string;
    imageUrl: string;
    token: string;

    constructor(
        name: string = '',
        email: string = '',
        imageUrl: string = '',
        token: string = ''
    ) {
        this.name = name;
        this.email = email;
        this.imageUrl = imageUrl;
        this.token = token;
    }
}