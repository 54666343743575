import { saveAs } from 'file-saver';
import { Injectable } from '@angular/core';
import * as html2canvas from 'html2canvas';
import htmlDocx from 'html-docx-js/dist/html-docx'

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  // Image width const
  private IMAGE_WIDTH = 400;

  constructor() { }

  exportToWord(panels) {
    const promise = this.convertPanelsToCanvases(panels);

    return promise.then((canvases) => {
      const images = canvases.map(canvas => this.convertCanvasToImage(canvas));
      const html = this.generateHtml(images);
      const docxFileData = htmlDocx.asBlob(html);
      const fileName = this.generateDateTimeFileName('.docx');

      saveAs(docxFileData, fileName);
    });
  }

  private convertPanelsToCanvases(panels) {
    const promises = [];
    
    panels.forEach(panel => {
      promises.push(html2canvas(panel));
    });

    return Promise.all(promises);
  }

  private convertCanvasToImage(canvas) {
    const scaleRatio = this.IMAGE_WIDTH / canvas.width;
    const width = this.IMAGE_WIDTH;
    const height = canvas.height * scaleRatio;

    const image = new Image(width, height);

    image.src = canvas.toDataURL();

    return image;
  }

  private generateHtml(images) {
    const html = document.createElement('html');
    
    const head = document.createElement('head');
    const body = document.createElement('body');

    const metaCharset = document.createElement('meta');
    metaCharset.setAttribute('charset', 'utf-8');

    head.appendChild(metaCharset);

    images.forEach((image, index) => {
      body.appendChild(image);

      // Add page breaks for all but last page
      if (index < images.length - 1) {
        const pageBreak = document.createElement('br');
        
        pageBreak.setAttribute('style', 'page-break-before: always');

        body.appendChild(pageBreak);
      }
    })
    
    html.appendChild(head);
    html.appendChild(body);

    return `<!doctype html> \n ${html.outerHTML}`;
  }

  private generateDateTimeFileName(fileTypeSuffix: string) {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toPrecision(2);
    const day = (now.getDate()).toPrecision(2);
    const hrs = (now.getHours()).toPrecision(2);
    const mins = (now.getMinutes()).toPrecision(2);
    const secs = (now.getSeconds()).toPrecision(2);

    return `${year}${month}${day}_${hrs}${mins}${secs}${fileTypeSuffix}`;
  }
}
