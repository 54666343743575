import { KeyTrendSettingsComponent } from "./components/key-trend-settings/key-trend-settings.component";
import { AuthGuard } from "./auth.guard";
import { AdminGuard } from "./admin.guard";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { KeyTrendsComponent } from "./components/key-trends/key-trends.component";
import { AnalyzeComponent } from "./components/analyze/analyze.component";
import { LoginComponent } from "./components/login/login.component";
import { ParamsComponent } from "./components/params/params.component";
import { ReportComponent } from "./components/report/report.component";
import { UploadComponent } from "./components/upload/upload.component";
import { DefaultsComponent } from "./components/defaults/defaults.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { AdminComponent } from "./components/admin/admin.component";
import { SignupComponent } from "./components/signup/signup.component";
import { ConfirmationComponent } from "./components/confirmation/confirmation.component";

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" }, // Default route
  { path: "analyze", component: AnalyzeComponent, canActivate: [AuthGuard] },
  { path: "login", component: LoginComponent },
  { path: "sign-up", component: SignupComponent },
  {
    path: "confirmation",
    component: ConfirmationComponent,
    canActivate: [AuthGuard],
  },
  { path: "params", component: ParamsComponent, canActivate: [AuthGuard] },
  { path: "report", component: ReportComponent, canActivate: [AuthGuard] },
  { path: "defaults", component: DefaultsComponent, canActivate: [AuthGuard] },
  { path: "upload", component: UploadComponent, canActivate: [AuthGuard] },
  { path: "trends", component: KeyTrendsComponent, canActivate: [AuthGuard] },
  {
    path: "trend-settings",
    component: KeyTrendSettingsComponent,
    canActivate: [AuthGuard],
  },
  { path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
  { path: "admin", component: AdminComponent, canActivate: [AdminGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
