import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../../utils/app.contants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version = AppConstants.version;
  currentYear = new Date().getFullYear()

  constructor() { }

  ngOnInit() {
  }

}
