import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { TrendReport } from 'src/app/models/trend-report-model';
import { Trend } from 'src/app/models/trend.model';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationType } from 'src/app/models/notification-type.enum';
import { MediaType } from 'src/app/models/media-type.enum';
import { MOBILE_REPORT_WIDTH, DEFAULT_REPORT_WIDTH, MOBILE_REPORT_HEIGHT, DEFAULT_REPORT_HEIGHT, MOBILE_REPORT_AXISTITLEFONT, DEFAULT_REPORT_AXISTITLEFONT, MOBILE_REPORT_TICKCOUNT, DEFAULT_REPORT_TICKCOUNT, MOBILE_REPORT_TICKFONT, DEFAULT_REPORT_TICKFONT, MOBILE_REPORT_MARGIN, DEFAULT_REPORT_MARGIN, MOBILE_REPORT_PADDING, DEFAULT_REPORT_PADDING } from 'src/app/models/chart.constants';
import { DataTools } from 'src/app/utils/data-tools';
import * as d3 from '../../utils/d3';
import * as Plotly from 'plotly.js';
import { TrendStatisticType } from 'src/app/models/trend-statistic-type.enum';
import { COLOR } from 'src/app/models/color-constants.enum';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-key-trends-chart',
  templateUrl: './key-trends-chart.component.html',
  styleUrls: ['./key-trends-chart.component.scss']
})
export class KeyTrendsChartComponent implements OnInit {

  @ViewChild('chart') element: ElementRef;

  @Input() trendReport: TrendReport;
  @Input() trends: Trend[];
  @Input() keyTrendChanged$: Observable<void>;

  get parameterTrends() {
    return this.trends.filter((trend: Trend) => trend.parameterId == this.trendReport.parameterId)
                      .sort((a, b) => a.epoch - b.epoch);
  }

  private CPK_TARGET: number = 1.33;

  private data: number[];
  private dataMedian: number;
  private isCpkReport: boolean = false;
  private mediaType: MediaType;

  constructor(
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.renderComponent();
    
    this.keyTrendChanged$.subscribe(() => {
      this.renderComponent();
    });
  }

  renderComponent(): void {
    try {
      this.mediaType = MediaType.detect();

      switch (this.trendReport.trendStatisticsType) {
        case TrendStatisticType.Mean:
          this.data = this.parameterTrends.map(trend => trend.mean)
          break;
        case TrendStatisticType.StandardDeviation:
          this.data = this.parameterTrends.map(trend => trend.standardDeviation)
          break;
        case TrendStatisticType.MaximumValue:
          this.data = this.parameterTrends.map(trend => trend.maximumValue)
          break;
        case TrendStatisticType.MinimumValue:
          this.data = this.parameterTrends.map(trend => trend.minimumValue)
          break;
        case TrendStatisticType.CpK:
          this.data = this.parameterTrends.map(trend => trend.cpk)
          this.isCpkReport = true;
          break;
        case TrendStatisticType.Cp:
          this.data = this.parameterTrends.map(trend => trend.cp)
          break;
        case TrendStatisticType.Cpm:
          this.data = this.parameterTrends.map(trend => trend.cpm)
          break;
        case TrendStatisticType.PpK:
          this.data = this.parameterTrends.map(trend => trend.ppk)
          break;
        case TrendStatisticType.Pp:
          this.data = this.parameterTrends.map(trend => trend.pp)
          break;
      }

      this.dataMedian = DataTools.median(this.data);

      this.draw();
    } catch (error) {
      this.notificationService.notify(error, NotificationType.DANGER);
    }
  }

  suggestedTickFormat(values, tickCount) {
    const range = DataTools.range(
      DataTools.max(values),
      DataTools.min(values)
    );

    const step = DataTools.segment(range, tickCount);

    return `.${d3.precisionFixed(step)}f`;
  }

  draw() {
    const traces = [
      {
        mode: 'markers',
        name: this.trendReport.trendStatisticsType,
        type: 'scatter',
        hoverinfo: 'all',
        y: this.data,
        x: this.data.map((_, idx) => idx),
        text: this.parameterTrends.map(trend => trend.timestamp),
        marker: {
          color: COLOR.STEEL_BLUE3,
          size: 5
        },
        showlegend: false
      },
      {
        mode: 'lines',
        name: this.trendReport.trendStatisticsType,
        type: 'scatter',
        hoverinfo: 'all',
        y: this.data,
        x: this.data.map((_, idx) => idx),
        text: this.parameterTrends.map(trend => trend.timestamp),
        marker: {
          color: COLOR.STEEL_BLUE3,
          opacity: .75
        },
        line: {
          width: 1
        },
        showlegend: false,
        connectgaps: false
      },
      this.isCpkReport ? {
        name: 'Cpk Target',
        mode: 'lines',
        type: 'scatter',
        hoverinfo: 'none',
        y: [this.CPK_TARGET, this.CPK_TARGET],
        x: [0, this.data.length - 1],
        marker: {
          color: COLOR.GREEN,
          opacity: 1
        },
        line: {
          width: 1
        },
        showlegend: true,
        connectgaps: false
      } : {
        name: 'Median',
        mode: 'lines',
        type: 'scatter',
        hoverinfo: 'none',
        y: [this.dataMedian, this.dataMedian],
        x: [0, this.data.length - 1],
        marker: {
          color: COLOR.FIRE_BRICK,
          opacity: 1
        },
        line: {
          width: 1
        },
        showlegend: true,
        connectgaps: false
      }
    ];

    // @ts-ignore
    Plotly.newPlot(this.element.nativeElement, traces, this.layout(), this.config());
  }

  layout() {
    return {
      autosize: true,
      width: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_WIDTH : DEFAULT_REPORT_WIDTH,
      height: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_HEIGHT : DEFAULT_REPORT_HEIGHT,
      hovermode: 'closest',
      xaxis: {
        titlefont: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_AXISTITLEFONT : DEFAULT_REPORT_AXISTITLEFONT,
        showline: false,
        showspikes: true,
        spikemode: "across",
        spikethickness: 1,
        spikedash: 'solid',
        nticks: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKCOUNT : DEFAULT_REPORT_TICKCOUNT,
        tickfont: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKFONT : DEFAULT_REPORT_TICKFONT,
        tickformat: this.suggestedTickFormat(this.data.map((_, idx) => idx), this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKCOUNT : DEFAULT_REPORT_TICKCOUNT)
        //tickformat: '%m/%d/%y %H:%M:%S' // For more time formatting types, see: https://github.com/d3/d3-time-format/blob/master/README.md
      },
      yaxis: {
        title: `${ this.trendReport.trendStatisticsType }`,
        titlefont: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_AXISTITLEFONT : DEFAULT_REPORT_AXISTITLEFONT,
        showline: false,
        nticks: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKCOUNT : DEFAULT_REPORT_TICKCOUNT,
        tickfont: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKFONT : DEFAULT_REPORT_TICKFONT,
        tickformat: this.suggestedTickFormat(this.data, this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKCOUNT : DEFAULT_REPORT_TICKCOUNT)
      },
      margin: {
        t: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_MARGIN : DEFAULT_REPORT_MARGIN,
        r: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_MARGIN : DEFAULT_REPORT_MARGIN,
        b: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_MARGIN : DEFAULT_REPORT_MARGIN,
        l: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_MARGIN : DEFAULT_REPORT_MARGIN,
        pad: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_PADDING : DEFAULT_REPORT_PADDING
      }
    }
  }

  config() {
    return { displayModeBar: true };
  }
}
