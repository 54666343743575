import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationType } from "src/app/models/notification-type.enum";
import { User } from "src/app/models/user.model";
import { AdminApiService } from "src/app/services/admin.api.service";
import { NotificationService } from "src/app/services/notification.service";
import { AuthService } from "../../services/auth.service";
import { ParameterApiService } from "../../services/parameter.api.service";
import { environment } from "./../../../environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private parameterApiService: ParameterApiService,
    private notificationService: NotificationService,
    private adminService: AdminApiService
  ) {}
  email: string;
  password: string;
  showModal: boolean = false;
  ngOnInit() {}

  //on click google button
  signInWithGoogle(): void {
    this.authService.doGoogleLogin().then((auth) => {
      //save only the user object fields that we need for now
      this.authService.currentUser = new User(
        auth.user.displayName,
        auth.user.email,
        auth.user.photoURL,
        auth.user.qa
      );
      this.router.navigate(["/upload"]);
    });
  }
  signInWithEmail(): void {
    this.authService.doEmailLogin(this.email, this.password).then((auth) => {
      //save only the user object fields that we need for now
      this.authService.currentUser = new User(
        auth.user.displayName,
        auth.user.email,
        auth.user.photoURL,
        auth.user.qa
      );

      this.adminService.getUser().subscribe(
        (user) => {
          if (user.license && user.license.trial) {
            if (this.isTrialExpired(user)) {
              this.notifyTrialExpired();
              this.authService.Logout();
            } else {
              this.router.navigate(["/upload"]);

              if (environment.production) {
                (<any>window).dataLayer.push({ event: "login" });
              }
            }
          } else {
            this.router.navigate(["/upload"]);

            if (environment.production) {
              (<any>window).dataLayer.push({ event: "login" });
            }
          }
        },
        (error) => {
          this.notifyTrialExpired();
          this.authService.Logout();
        }
      );
    });
  }
  isTrialExpired(user): boolean {
    let endStrings = user.license.trial.end.split("-");
    var today = new Date();
    let endDate = new Date(Date.parse(user.license.trial.end));
    if (today > endDate) {
      return true;
    }
  }
  notifyTrialExpired() {
    this.authService.currentUser = null;
    this.notificationService.notify(
      "Your trial is expired.  Visit gotrendable.com/pricing to purchase a subscription.",
      NotificationType.INFO
    );
  }
  checkSignInMethods() {
    this.authService.fetchSignInMethodsForEmail(this.email).then((result) => {
      if (result) {
        this.forgotPassword();
        // this user is in the system
        // check if they are a "password" provider type
        /* if(result[0]==="password"){
          // this user can reset their password.
          this.forgotPassword();
        }
        else if(result[0]==="google.com"){
          // this is a google user.
          this.notificationService.notify("This account is managed by Google. Use the 'Sign in with Google' button to access your account.", NotificationType.INFO);   
        } */
      } else {
        this.notificationService.notify(
          "There is no user account associated with that email address.",
          NotificationType.INFO
        );
      }
    });
  }
  showForgotPassword() {
    this.showModal = true;
  }
  forgotPassword(): void {
    this.authService.forgotPassword(this.email).then((res) => {
      this.showModal = false;
      this.notificationService.notify(
        "Check your email for a link to reset your password.",
        NotificationType.INFO
      );
    });
  }
}
