import { DataTools } from './../../utils/data-tools';
import { Component, OnInit, Input } from '@angular/core';
import { Parameter } from '../../models/parameter.model';
import { LateralService } from '../../services/lateral.service';
import { ReportService } from 'src/app/services/report.service';
import { CapabilityReport } from 'src/app/models/capability-report.model';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationType } from 'src/app/models/notification-type.enum';
import { Field } from 'src/app/models/field.model';
@Component({
  selector: 'app-statistics-table',
  templateUrl: './statistics-table.component.html',
  styleUrls: ['./statistics-table.component.scss']
})
export class StatisticsTableComponent implements OnInit {

  @Input() field: Field;

  isLoading: boolean;
  data: number[];
  parameter: Parameter;
  capabilityReport: CapabilityReport;

  processParametersExpanded = true;
  processCharacteristicsExpanded = true
  capabilityOverallExpanded = true;
  capabilityWithinExpanded = true;

  constructor(
    private lateralService: LateralService,
    private reportService: ReportService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    try {
      this.parameter = this.lateralService.getParameter(this.field);
      this.data = this.lateralService.getData(this.field);

      this.isLoading = true;
      this.reportService.chart(this.field, this.parameter, this.data).subscribe(report => {      
          this.capabilityReport = new CapabilityReport(report['Statistics'][0]);
        }, 
        error => { this.notificationService.notify(error, NotificationType.DANGER); }, 
        () => { this.isLoading = false; }
      );
    } catch(error) {
      this.notificationService.notify(error, NotificationType.DANGER);
    }
  }
}
