import { Injectable } from '@angular/core';
import { ControlLimitSegment } from '../models/control-limit-segment.model';
import * as Plotly from 'plotly.js';

@Injectable()
export class ControlLimitService {

    toControlLimitPoints(limitValues: number[]): Array<Plotly.Point> {
        const segments: ControlLimitSegment[] = this.calculateLimitSegments(limitValues);
        const points: Plotly.Point[] = [];

        // Add one to each index to reflect chart offset rules
        segments.forEach(segment => {
            const startPoint = {
                // If not the first point, stagger back one point so that the end index of preceding segment 
                // and start index of this segment are the same x value and create a vertical line to new y
                // value segment
                x: (segment.startIdx === 0 ? 0 : segment.startIdx - 1) + 1,
                y: segment.value,
                z: 0
            }

            const endPoint = {
                x: segment.endIdx + 1,
                y: segment.value,
                z: 0
            }

            points.push(...[startPoint, endPoint]);
        });

        return points;
    }

    private calculateLimitSegments(limitValues: number[]): ControlLimitSegment[] {
        let nextStartIdx = 0
        let limitSegments = [];
        let reachedEnd = false;

        while (!reachedEnd) {
            const limitSegment = this.getControlLimitSegment(nextStartIdx, limitValues);

            limitSegments.push(limitSegment);
            nextStartIdx = limitSegment.endIdx + 1;

            if (limitSegment.endIdx === limitValues.length - 1) reachedEnd = true;
        }

        return limitSegments;
    }

    private getControlLimitSegment(startIdx: number, limitValues: number[]): ControlLimitSegment {
        const controlLimit = limitValues[startIdx];

        for (let i = startIdx; i < limitValues.length; i++) {
            
            if (controlLimit !== limitValues[i]) {
                return new ControlLimitSegment(controlLimit, startIdx, i - 1);
            }

            if (i === limitValues.length - 1) {
                return new ControlLimitSegment(controlLimit, startIdx, i);
            }
        }
    }
}
