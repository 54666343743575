import { Field } from '../../models/field.model';
import { MediaType } from '../../models/media-type.enum';
import { NotificationService } from '../../services/notification.service';
import { CapabilityReport } from '../../models/capability-report.model';
import { COLOR } from '../../models/color-constants.enum';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import * as Plotly from 'plotly.js';

import { Chart } from '../../models/chart.interface';
import { DataTools } from '../../utils/data-tools';
import { 
  DEFAULT_REPORT_WIDTH, 
  DEFAULT_REPORT_HEIGHT, 
  DEFAULT_REPORT_MARGIN,
  DEFAULT_REPORT_PADDING,
  DEFAULT_REPORT_TICKFONT,
  DEFAULT_REPORT_TICKCOUNT,
  MOBILE_REPORT_WIDTH,
  MOBILE_REPORT_HEIGHT,
  MOBILE_REPORT_TICKCOUNT,
  MOBILE_REPORT_TICKFONT,
  MOBILE_REPORT_MARGIN,
  MOBILE_REPORT_PADDING,
  MOBILE_REPORT_AXISTITLEFONT,
  DEFAULT_REPORT_AXISTITLEFONT
} from '../../models/chart.constants';
import { Parameter } from 'src/app/models/parameter.model';
import { LateralService } from '../../services/lateral.service';
import * as d3 from '../../utils/d3';
import { ReportService } from 'src/app/services/report.service';
import { NotificationType } from 'src/app/models/notification-type.enum';

@Component({
  selector: 'app-capability-histogram',
  templateUrl: './capability-histogram.component.html',
  styleUrls: ['./capability-histogram.component.scss']
})
export class CapabilityHistogramComponent implements OnInit, Chart {

  @ViewChild('chart') element: ElementRef;

  @Input() field: Field;

  public isLoading = false;

  private readonly STD_DEVS = 3;
  private readonly BINS = 10;
  private readonly BAR_GAP = .01;
  private readonly NORM_DIST_CURVE_DENSITY = 1000;

  private data: number[];
  private parameter: Parameter;
  private capabilityReport: CapabilityReport
  private dataRange: number
  private step: number
  private mediaType: MediaType

  constructor(
    private lateralService: LateralService,
    private reportService: ReportService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    try {
      this.mediaType = MediaType.detect();
      this.parameter = this.lateralService.getParameter(this.field);
      this.data = this.lateralService.getData(this.field).filter(value => value !== null);

      this.isLoading = true;
      this.reportService.chart(this.field, this.parameter, this.data).subscribe(report => {
          this.capabilityReport = new CapabilityReport(report['Statistics'][0]);
          this.dataRange = DataTools.range(this.capabilityReport.min, this.capabilityReport.max);
          this.step = this.getBinStep(this.capabilityReport.mean, this.capabilityReport.stdDev, this.STD_DEVS);

          this.draw();
        }, 
        error => { this.notificationService.notify(error, NotificationType.DANGER); },
        () => { this.isLoading = false }
      );
    } catch(error) {
      this.notificationService.notify(error, NotificationType.DANGER);
    }
  }

  suggestedTickFormat(range, tickCount) {
    const step = DataTools.segment(range, tickCount);

    return `.${d3.precisionFixed(step)}f`;
  }

  draw() {
    const traces = [
      this.histogram(), 
      this.curve(
        this.normalDistCurve(this.capabilityReport.mean, this.capabilityReport.stdDev, this.STD_DEVS),
        "Overall",
        COLOR.STEEL_BLUE1
      ),
      this.capabilityReport.target != null ? 
        this.curve(
          this.normalDistCurve(this.capabilityReport.target, this.capabilityReport.stdDev, this.STD_DEVS),
          "Target",
          COLOR.STEEL_BLUE4
        ) : null
    ].filter(it => it != null);

    // @ts-ignore
    Plotly.plot(this.element.nativeElement, traces, this.layout(), this.config());
  }

  getBinStep(mean, stdev, stdevsFromMean) {
    // Start / End are +/- passed in stddevs from mean
    const offset = stdevsFromMean * stdev;
    const start = mean - offset;
    const end = mean + offset;

    const range = end - start;
    return range / this.BINS;
  }

  histogram() {
    return {
      name: `${this.parameter.Name}`,
      type: 'histogram',
      hoverinfo: 'all',
      x: this.data,
      autobinx: false,
      xbins: {
        start: this.capabilityReport.min,
        end: this.capabilityReport.max,
        size: this.step
      },
      marker: {
        color: COLOR.STEEL_BLUE3,
        opacity: .75
      },
      showlegend: false
    }
  }

  curve(curve: Array<Partial<Plotly.Point>>, name, color): Partial<Plotly.ScatterData> {
    return {
      name: name,
      marker: { color: color },
      x: curve.map(point => point.x),
      y: curve.map(point => point.y),
      mode: 'lines',
      hoverinfo: 'all',
      line: { 
        shape: 'spline',
        width: 1
      },
    }
  }

  normalDistCurve(mean, stdev, stdevsFromMean): Array<Partial<Plotly.Point>> {
    const curve: Array<Partial<Plotly.Point>> = [];

    // Start / End are +/- passed in stddevs from mean
    const offset = stdevsFromMean * stdev;
    const start = mean - offset;
    const end = mean + offset;

    // Create points for normal distribution line
    for (let x = start; x <= end; x += (this.dataRange / this.NORM_DIST_CURVE_DENSITY)) {
      curve.push({
        x: x,
        y: DataTools.normalPDF(x, mean, stdev, this.step, this.data.length)
      });
    }

    return curve;
  }

  layout() {
    return {
      showlegend: this.mediaType == MediaType.MOBILE ? false : true,
      bargap: this.BAR_GAP,
      bargroupgap: this.BAR_GAP,
      barmode: "overlay",
      hovermode: 'closest',
      autosize: true,
      width: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_WIDTH : DEFAULT_REPORT_WIDTH,
      height: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_HEIGHT : DEFAULT_REPORT_HEIGHT,
      xaxis: {
        title: `${this.parameter.Name} (${this.parameter.Units})`,
        titlefont: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_AXISTITLEFONT : DEFAULT_REPORT_AXISTITLEFONT,
        showline: false,
        showspikes: true,
        spikemode: "across",
        spikethickness: 1,
        spikedash: 'solid',
        nticks: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKCOUNT : DEFAULT_REPORT_TICKCOUNT,
        tickangle: -45,
        tickfont: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKFONT : DEFAULT_REPORT_TICKFONT,
        tickformat: this.suggestedTickFormat(this.dataRange, this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKCOUNT : DEFAULT_REPORT_TICKCOUNT)
      },
      yaxis: {
        showline: false,
        nticks: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKCOUNT : DEFAULT_REPORT_TICKCOUNT,
        tickfont: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKFONT : DEFAULT_REPORT_TICKFONT
      },
      margin: {
        t: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_MARGIN : DEFAULT_REPORT_MARGIN,
        r: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_MARGIN : DEFAULT_REPORT_MARGIN,
        b: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_MARGIN : DEFAULT_REPORT_MARGIN,
        l: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_MARGIN : DEFAULT_REPORT_MARGIN,
        pad: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_PADDING : DEFAULT_REPORT_PADDING
      },
      annotations: [
        this.capabilityReport.lowerSpec != null ? {
          align: 'left',
          text: 'LSL',
          yref: 'paper',
          yanchor: 'bottom',
          hovertext: this.capabilityReport.lowerSpec,
          x: this.capabilityReport.lowerSpec,
          y: 1,
          font: { color: COLOR.DARK_GOLDENROD1 },
          showarrow: false
        } : null,
        this.capabilityReport.upperSpec != null ? {
          align: 'left',
          text: 'USL',
          yref: 'paper',
          yanchor: 'bottom',
          hovertext: this.capabilityReport.upperSpec,
          x: this.capabilityReport.upperSpec,
          y: 1,
          font: { color: COLOR.DARK_GOLDENROD1 },
          showarrow: false
        } : null,
        this.capabilityReport.target != null ? 
        {
          align: 'left',
          text: 'Target',
          yref: 'paper',
          yanchor: 'bottom',
          hovertext: this.capabilityReport.target,
          x: this.capabilityReport.target,
          y: 1,
          font: { color: COLOR.DARK_GOLDENROD1 },
          showarrow: false
        } : null
      ].filter(it => it != null),
      shapes: [
        this.capabilityReport.lowerSpec != null ? { 
          type: 'line',
          yref: 'paper',
          x0: this.capabilityReport.lowerSpec,
          y0: 0,
          x1: this.capabilityReport.lowerSpec,
          y1: 1,
          line: {
            color: COLOR.DARK_GOLDENROD1,
            dash: 'dash',
            width: 1
          }
        } : null,
        this.capabilityReport.upperSpec != null ? {
          type: 'line',
          yref: 'paper',
          x0: this.capabilityReport.upperSpec,
          y0: 0,
          x1: this.capabilityReport.upperSpec,
          y1: 1,
          line: {
            color: COLOR.DARK_GOLDENROD1,
            dash: 'dash',
            width: 1
          }
        } : null,
        this.capabilityReport.target != null ? 
        {
          type: 'line',
          yref: 'paper',
          x0: this.capabilityReport.target,
          y0: 0,
          x1: this.capabilityReport.target,
          y1: 1,
          line: {
            color: COLOR.DARK_GOLDENROD1,
            dash: 'dash',
            width: 1
          }
        } : null
      ].filter(it => it != null)
    }
  }

  config() {
    return { displayModeBar: true };
  }
}
