import { ConfirmationService } from '../../services/confirmation.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  get showConfirmation() {
    return this.confirmationService.showConfirmation;
  }

  get header() {
    return this.confirmationService.header;
  }

  get body() {
    return this.confirmationService.body;
  }
  
  get resolveMessage() {
    return this.confirmationService.resolveMessage;
  }

  get rejectMessage() {
    return this.confirmationService.rejectMessage;
  }

  get resolveBtnClass() {
    return this.confirmationService.resolveBtnClass;
  }

  get rejectBtnClass() {
    return this.confirmationService.rejectBtnClass;
  }

  constructor(private confirmationService: ConfirmationService) { }

  ngOnInit() {
  }

  resolveConfirmation() {
    this.confirmationService.resolveConfirmation();
  }

  rejectConfirmation() {
    this.confirmationService.rejectConfirmation('User clicked reject');
  }

}
