import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from "@angular/router";
import { AngularFireAuth } from 'angularfire2/auth';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { User } from './models/user.model';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(): Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser()
      .then(user => {
        this.authService.currentUser = new User(
          user.displayName,
          user.email,
          user.photoURL,
          user.qa
        );
        
        return resolve(true);
      }, err => {
        this.router.navigate(['/login']);
        return resolve(true);
      })
    })
  }
}
