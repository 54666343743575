export enum MediaType {
    MOBILE,
    TOUCH,
    TABLET,
    DESKTOP
}

export namespace MediaType {
    const MOBILE_MIN = 0;
    const MOBILE_MAX = 768;
    
    const TABLET_MIN = 769;
    const TABLET_MAX = 1023;

    const DESKTOP_MIN = 1024;
    const DESKTOP_MAX = Number.MAX_VALUE;

    export function ranges() {
        const ranges = { };

        ranges[MediaType.MOBILE] = { start: MOBILE_MIN, end: MOBILE_MAX };
        ranges[MediaType.TOUCH] = { start: MOBILE_MIN, end: TABLET_MAX };
        ranges[MediaType.TABLET] = { start: TABLET_MIN, end: TABLET_MAX };
        ranges[MediaType.DESKTOP] = { start: DESKTOP_MIN, end: DESKTOP_MAX };

        return ranges;
    }

    export function detect(): MediaType {
        const mediaRanges = MediaType.ranges();

        if (window.innerWidth.inRange(mediaRanges[MediaType.MOBILE].start, mediaRanges[MediaType.MOBILE].end)) {
            return MediaType.MOBILE;
        } else if (window.innerWidth.inRange(mediaRanges[MediaType.TABLET].start, mediaRanges[MediaType.TABLET].end)) {
            return MediaType.TABLET;
        } else if (window.innerWidth.inRange(mediaRanges[MediaType.DESKTOP].start, mediaRanges[MediaType.DESKTOP].end)) {
            return MediaType.DESKTOP;
        }
    }
}
