import { Parameter } from '../models/parameter.model';
import { ReportType } from '../models/report-type.enum';

export class PanelTool {
    static title(reportType: ReportType, parameter: Parameter) {
        if (parameter) {
            const base = `${parameter.Name}`;
            const subGroupSize = parameter.SubGroupSize || 1; // This is a temporary fix to maintain compatibility with parameters with no specified subgroup size
      
            switch(reportType) {
              case ReportType.None: 
                return `Select a chart for ${base}`
      
              case ReportType.CapabilityHistogram:
                return `${base} Capability`;
        
              case ReportType.SPCChart: {
                if (subGroupSize == 1) {
                  return `${base} I-Chart`;
                } else if (subGroupSize > 1) {
                  return `${base} XBar-Chart`;
                }
              }
                
              case ReportType.RangeChart: {
                if (subGroupSize == 1) {
                  return `${base} MR-Chart`;
                } else if (subGroupSize >= 2 && subGroupSize <= 8) {
                  return `${base} R-Chart`;
                } else if (subGroupSize == 9) {
                  return `${base} S-Chart`;
                }
              }
        
              case ReportType.Statistics:
                return `${base} Statistics`;
        
              case ReportType.FailedTests:
                return `${base} Failed Tests`;
            }
          } else {
            return 'Select a Field';
          }
    }
}