import { RangeReport } from './range-report.model';
import { FailedTestReport } from './failed-test-report.model';
import { SPCReport } from "./spc-report.model";
import { FailedTestPoint } from './failed-test-point.model';

export class FailedTestReports {

    test1SPCReport: FailedTestReport = new FailedTestReport();
    test2SPCReport: FailedTestReport = new FailedTestReport();
    test3SPCReport: FailedTestReport = new FailedTestReport();
    test4SPCReport: FailedTestReport = new FailedTestReport();
    test5SPCReport: FailedTestReport = new FailedTestReport();
    test6SPCReport: FailedTestReport = new FailedTestReport();
    test7SPCReport: FailedTestReport = new FailedTestReport();
    test8SPCReport: FailedTestReport = new FailedTestReport();

    test1RangeReport: FailedTestReport = new FailedTestReport();
    test2RangeReport: FailedTestReport = new FailedTestReport();
    test3RangeReport: FailedTestReport = new FailedTestReport();
    test4RangeReport: FailedTestReport = new FailedTestReport();

    constructor(spcReport: SPCReport, rangeReport: RangeReport) {
        if (spcReport) this.prepareSPCTestInfo(spcReport);
        if (rangeReport) this.prepareRangeTestInfo(rangeReport);
    }

    prepareSPCTestInfo(spcReport: SPCReport) {
        // const test1SPCFailIndexes = [];
        // const test2SPCFailIndexes = [];
        // const test3SPCFailIndexes = [];
        // const test4SPCFailIndexes = [];
        // const test5SPCFailIndexes = [];
        // const test6SPCFailIndexes = [];
        // const test7SPCFailIndexes = [];
        // const test8SPCFailIndexes = [];

        this.test1SPCReport.testPerformed = spcReport.performedTest1;
        this.test2SPCReport.testPerformed = spcReport.performedTest2;
        this.test3SPCReport.testPerformed = spcReport.performedTest3;
        this.test4SPCReport.testPerformed = spcReport.performedTest4;
        this.test5SPCReport.testPerformed = spcReport.performedTest5;
        this.test6SPCReport.testPerformed = spcReport.performedTest6;
        this.test7SPCReport.testPerformed = spcReport.performedTest7;
        this.test8SPCReport.testPerformed = spcReport.performedTest8;

        spcReport.values.forEach((value, idx) => {
            if (spcReport.failedTest1.includes(idx + 1)) {
                this.test1SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
            }

            if (spcReport.failedTest2.includes(idx + 1)) {
                this.test2SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
            }

            if (spcReport.failedTest3.includes(idx + 1)) {
                this.test3SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
            }

            if (spcReport.failedTest4.includes(idx + 1)) {
                this.test4SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
            }

            if (spcReport.failedTest5.includes(idx + 1)) {
                this.test5SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
            }

            if (spcReport.failedTest6.includes(idx + 1)) {
                this.test6SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
            }

            if (spcReport.failedTest7.includes(idx + 1)) {
                this.test7SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
            }

            if (spcReport.failedTest8.includes(idx + 1)) {
                this.test8SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
            }
        })

                // spcReport.values.forEach((value, idx) => {
        //     if (!test1SPCFailIndexes.includes(idx + 1) && 
        //         spcReport.failedTest2.includes(idx + 1)
        //     ) {
        //         test2SPCFailIndexes.push(idx + 1);
        //         this.test2SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
        //     }
        // });

        // spcReport.values.forEach((value, idx) => {
        //     if (!test1SPCFailIndexes.includes(idx + 1) && 
        //         !test2SPCFailIndexes.includes(idx + 1) && 
        //         spcReport.failedTest3.includes(idx + 1)
        //     ) {
        //         test3SPCFailIndexes.push(idx + 1);
        //         this.test3SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
        //     }
        // });

        // spcReport.values.forEach((value, idx) => {
        //     if (!test1SPCFailIndexes.includes(idx + 1) && 
        //         !test2SPCFailIndexes.includes(idx + 1) && 
        //         !test3SPCFailIndexes.includes(idx + 1) && 
        //         spcReport.failedTest4.includes(idx + 1)
        //     ) {
        //         test4SPCFailIndexes.push(idx + 1);
        //         this.test4SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
        //     }
        // });

        // spcReport.values.forEach((value, idx) => {
        //     if (!test1SPCFailIndexes.includes(idx + 1) && 
        //         !test2SPCFailIndexes.includes(idx + 1) && 
        //         !test3SPCFailIndexes.includes(idx + 1) && 
        //         !test4SPCFailIndexes.includes(idx + 1) && 
        //         spcReport.failedTest5.includes(idx + 1)
        //     ) {
        //         test5SPCFailIndexes.push(idx + 1);
        //         this.test5SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
        //     }
        // });

        // spcReport.values.forEach((value, idx) => {
        //     if (!test1SPCFailIndexes.includes(idx + 1) && 
        //         !test2SPCFailIndexes.includes(idx + 1) && 
        //         !test3SPCFailIndexes.includes(idx + 1) && 
        //         !test4SPCFailIndexes.includes(idx + 1) && 
        //         !test5SPCFailIndexes.includes(idx + 1) && 
        //         spcReport.failedTest6.includes(idx + 1)
        //     ) {
        //         test6SPCFailIndexes.push(idx + 1);
        //         this.test6SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
        //     }
        // });

        // spcReport.values.forEach((value, idx) => {
        //     if (!test1SPCFailIndexes.includes(idx + 1) && 
        //         !test2SPCFailIndexes.includes(idx + 1) && 
        //         !test3SPCFailIndexes.includes(idx + 1) && 
        //         !test4SPCFailIndexes.includes(idx + 1) && 
        //         !test5SPCFailIndexes.includes(idx + 1) &&
        //         !test6SPCFailIndexes.includes(idx + 1) && 
        //         spcReport.failedTest7.includes(idx + 1)
        //     ) {
        //         test7SPCFailIndexes.push(idx + 1);
        //         this.test7SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
        //     }
        // });

        // spcReport.values.forEach((value, idx) => {
        //     if (!test1SPCFailIndexes.includes(idx + 1) && 
        //         !test2SPCFailIndexes.includes(idx + 1) && 
        //         !test3SPCFailIndexes.includes(idx + 1) && 
        //         !test4SPCFailIndexes.includes(idx + 1) && 
        //         !test5SPCFailIndexes.includes(idx + 1) &&
        //         !test6SPCFailIndexes.includes(idx + 1) && 
        //         !test7SPCFailIndexes.includes(idx + 1) && 
        //         spcReport.failedTest8.includes(idx + 1)
        //     ) {
        //         test8SPCFailIndexes.push(idx + 1);
        //         this.test8SPCReport.points.push(new FailedTestPoint(idx + 1, value, spcReport.lowerSpec, spcReport.upperSpec));
        //     }
        // });
    }

    prepareRangeTestInfo(rangeReport: RangeReport) {
        // const test1RangeFailIndexes = [];
        // const test2RangeFailIndexes = [];
        // const test3RangeFailIndexes = [];
        // const test4RangeFailIndexes = [];

        this.test1RangeReport.testPerformed = rangeReport.performedTest1;
        this.test2RangeReport.testPerformed = rangeReport.performedTest2;
        this.test3RangeReport.testPerformed = rangeReport.performedTest3;
        this.test4RangeReport.testPerformed = rangeReport.performedTest4;

        rangeReport.movingRange.forEach((value, idx) => {
            if (rangeReport.failedTest1.includes(idx + 1)) {
                this.test1RangeReport.points.push(new FailedTestPoint(idx + 1, value, null, null));
            }

            if (rangeReport.failedTest2.includes(idx + 1)) {
                this.test2RangeReport.points.push(new FailedTestPoint(idx + 1, value, null, null));
            }

            if (rangeReport.failedTest3.includes(idx + 1)) {
                this.test3RangeReport.points.push(new FailedTestPoint(idx + 1, value, null, null));
            }

            if (rangeReport.failedTest4.includes(idx + 1)) {
                this.test4RangeReport.points.push(new FailedTestPoint(idx + 1, value, null, null));
            }
        });

        // rangeReport.movingRange.forEach((value, idx) => {
        //     if (!test1RangeFailIndexes.includes(idx + 1) &&
        //         rangeReport.failedTest2.includes(idx + 1)
        //     ) {
        //         test2RangeFailIndexes.push(idx + 1);
        //         this.test2RangeReport.points.push(new FailedTestPoint(idx + 1, value, null, null));
        //     }
        // });

        // rangeReport.movingRange.forEach((value, idx) => {
        //     if (!test1RangeFailIndexes.includes(idx + 1) &&
        //         !test2RangeFailIndexes.includes(idx + 1) &&
        //         rangeReport.failedTest3.includes(idx + 1)
        //     ) {
        //         test3RangeFailIndexes.push(idx + 1);
        //         this.test3RangeReport.points.push(new FailedTestPoint(idx + 1, value, null, null));
        //     }
        // });

        // rangeReport.movingRange.forEach((value, idx) => {
        //     if (!test1RangeFailIndexes.includes(idx + 1) &&
        //         !test2RangeFailIndexes.includes(idx + 1) &&
        //         !test3RangeFailIndexes.includes(idx + 1) &&
        //         rangeReport.failedTest4.includes(idx + 1)
        //     ) {
        //         test4RangeFailIndexes.push(idx + 1);
        //         this.test4RangeReport.points.push(new FailedTestPoint(idx + 1, value, null, null));
        //     }
        // });
    }

    pointFailedSPCTests(index: number): boolean {
        return this.test1SPCReport.isPointIndexFailed(index) ||
                this.test2SPCReport.isPointIndexFailed(index) ||
                this.test3SPCReport.isPointIndexFailed(index) ||
                this.test4SPCReport.isPointIndexFailed(index) ||
                this.test5SPCReport.isPointIndexFailed(index) ||
                this.test6SPCReport.isPointIndexFailed(index) ||
                this.test7SPCReport.isPointIndexFailed(index) ||
                this.test8SPCReport.isPointIndexFailed(index);
    }

    pointFailedRangeTests(index: number): boolean {
        return this.test1RangeReport.isPointIndexFailed(index) ||
            this.test2RangeReport.isPointIndexFailed(index) ||
            this.test3RangeReport.isPointIndexFailed(index) ||
            this.test4RangeReport.isPointIndexFailed(index)
    }
}