import { LoadingService } from '../../services/loading.service';
import { NotificationService } from '../../services/notification.service';
import { PanelTool } from '../../utils/panel-tool';
import { StatsType } from '../../models/stats-type.enum';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ReportType } from '../../models/report-type.enum';
import { LateralService } from '../../services/lateral.service';
import { ParameterApiService } from '../../services/parameter.api.service';
import { Parameter } from '../../models/parameter.model';
import { Field } from '../../models/field.model';
import { DEFAULT_COLUMN_WIDTH, DEFAULT_ROW_HEIGHT, DEFAULT_NUM_COLUMNS, DEFAULT_MARGIN, DEFAULT_OUTER_MARGIN, DEFAULT_X, DEFAULT_Y, DEFAULT_COL_SIZE, DEFAULT_ROW_SIZE, MOBILE_COLUMN_WIDTH, MOBILE_ROW_HEIGHT, MOBILE_NUM_COLUMNS, MOBILE_MARGIN, MOBILE_OUTER_MARGIN } from '../../models/grid.constants';
import { ReportDefaultsService } from '../../services/report-defaults.service';
import { ReportDefaults } from '../../models/report-defaults.model';
import { NotificationType } from '../../models/notification-type.enum';
import { ActivatedRoute } from '@angular/router';
import { MediaType } from '../../models/media-type.enum';
import { Report } from 'src/app/models/report.model';
import { ExportService } from 'src/app/services/export.service';
import { ConfirmationService } from 'src/app/services/confirmation.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  
  @ViewChild("reportContainer") reportContainer;

  // Make accessible to component
  ReportType = ReportType;
  StatsType = StatsType;
  
  showReportModal: boolean = false
  editingReport: Report = null
  selectedField: Field = null
  selectedType: ReportType = null
  showSelectField: boolean = false;
  showSelectType: boolean = false;
  
  get reports(): Array<Report> {
    return this.lateralService.reports;
  }

  set reports(value: Array<Report>) {
    this.lateralService.reports = value;
  }

  get fields(): Array<Field> {
    // Only show fields with a mapped parameter
    return this.lateralService.fields.filter((it) => !!it.mappedParameter);
  }

  get allowSaveReport(): Boolean {
    return !!this.selectedType && !!this.selectedField;
  }

  constructor(
    private lateralService: LateralService,
    private parameterService: ParameterApiService,
    private reportDefaultsService: ReportDefaultsService,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private exportService: ExportService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    let isCordovaApp = document.URL.indexOf('http://') === -1
    && document.URL.indexOf('https://') === -1;
    
    try {
      this.notificationService.dismissAll();

      if (this.reports.length) {
        // Cleanup any reports that may already be present but had their parameter mapping removed
        this.reports.filter(report => !this.lateralService.getParameter(report.field)).forEach(report => {
            this.removeReport(report);
          });
      }

      if (!this.fields.length) {
        this.notificationService.notify('No spec limits selected. Please analyze a new file and select spec limits to see reports', NotificationType.INFO);
        
        this.reports = [];
      } else if (!this.reports.length) {
        this.reports = [];

        // Load parameters
        this.loadingService.startLoading('Loading Spec Limits');
        this.parameterService.getAPIParameters().subscribe(
          parameters => {
            this.lateralService.parameters = parameters
      
            // Load report defaults
            this.loadingService.startLoading('Loading Defaults');
            this.reportDefaultsService.getDefaults().subscribe(
              reportDefaults => { 
                this.loadingService.stopLoading();
                this.lateralService.reportDefaults = !Array.isArray(reportDefaults) && reportDefaults ? reportDefaults : new ReportDefaults() 
      
                // If there are associated spec limits, matching parameters, and report defaults, 
                // then setup default layout for each param
                if (this.fields.length && 
                  this.lateralService.parameters.length && 
                  (
                    this.lateralService.reportDefaults.showSPCChart ||
                    this.lateralService.reportDefaults.showRangeChart ||
                    this.lateralService.reportDefaults.showFailedTests ||
                    this.lateralService.reportDefaults.showCapabilityHistogram ||
                    this.lateralService.reportDefaults.showStatistics
                  )
                ) {
                  this.fields.forEach(field => {
                    if (this.lateralService.getParameter(field)) {
                      if (this.lateralService.reportDefaults.showSPCChart) this.saveReport(new Report(ReportType.SPCChart, field));
                      if (this.lateralService.reportDefaults.showRangeChart) this.saveReport(new Report(ReportType.RangeChart, field));
                      if (this.lateralService.reportDefaults.showFailedTests) this.saveReport(new Report(ReportType.FailedTests, field));
                      if (this.lateralService.reportDefaults.showCapabilityHistogram) this.saveReport(new Report(ReportType.CapabilityHistogram, field));
                      if (this.lateralService.reportDefaults.showStatistics) this.saveReport(new Report(ReportType.Statistics, field));
                    }
                  });
                }
              },
              error => { 
                this.loadingService.stopLoading();
                this.notificationService.notify(error, NotificationType.DANGER); 
              }
            )
          }, 
          error => { 
            this.loadingService.stopLoading();
            this.notificationService.notify(error, NotificationType.DANGER); 
          }
        );
      }
    } catch(error) {
      this.loadingService.stopLoading();
      this.notificationService.notify(error, NotificationType.DANGER);
    }
  }

  showPrompt() {
    this.showReportModal = true;
    this.showSelectField = true;
    this.showSelectType = false;

    this.selectedField = this.fields.length ? this.fields[0] : null;
  }

  dismissPrompt() {
    this.showReportModal = false;
    this.showSelectField = false;
    this.showSelectType = false;
    
    this.editingReport = null;
    this.selectedType = null;
    this.selectedField = null;
  }

  saveReport(report: Report) {
    if (this.editingReport) {
      this.editingReport.type = this.selectedType;
      this.editingReport.field = this.selectedField;
    } else {
      if (!report) report = new Report(
        this.selectedType,
        this.selectedField
      );
      
      this.reports.push(report);
    }

    if (this.showReportModal) this.dismissPrompt();
  }

  export() {
    this.loadingService.startLoading('Exporting to Microsoft Word');

    const panels = this.reportContainer.nativeElement.querySelectorAll('.report-panel');

    this.exportService.exportToWord(panels)
      .catch(error => {
        this.loadingService.stopLoading();
        this.notificationService.notify(error, NotificationType.DANGER);
      }).then(() => {
        this.loadingService.stopLoading();
      });
  }

  selectField(field: Field) {
    this.selectedField = field;
  }

  confirmSelectedField() {
    this.showSelectField = false;
    this.showSelectType = true;

    this.selectedType = ReportType.SPCChart;
  }

  selectType(type: ReportType) {
    this.selectedType = type;
  }

  moveUpReport(report: Report) {
    const index = this.reports.indexOf(report);

    // Can only move up if not already at beginning
    if (index > 0) {
      // Remove item from the array
      this.reports.splice(index, 1);

      // Reinsert in front of last position
      this.reports.splice(index - 1, 0, report);
    }
  }

  moveDownReport(report: Report) {
    const index = this.reports.indexOf(report);

    // Can only move down if not already at end
    if (index < this.reports.length - 1) {
      // Remove item from the array
      this.reports.splice(index, 1);

      // Reinsert in behind last position
      this.reports.splice(index + 1, 0, report);
    }
  }

  editReport(report: Report) {
    this.editingReport = report;
    this.selectedType = report.type;
    this.selectedField = report.field;
    
    this.showPrompt();
  }

  removeReport(report: Report) {
    this.reports.splice(this.reports.indexOf(report), 1);
  }

  title(report: Report) {
    return PanelTool.title(report.type, report.field ? this.lateralService.getParameter(report.field) : null);
  }
}
