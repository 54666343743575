import { ParameterTest } from "./parameter-test.model";

export class ParameterTests {

    Test1: ParameterTest
    Test2: ParameterTest
    Test3: ParameterTest
    Test4: ParameterTest
    Test5: ParameterTest
    Test6: ParameterTest
    Test7: ParameterTest
    Test8: ParameterTest

    constructor() {
        this.Test1 = new ParameterTest(true, 3, 'One_point_greater_than_K_StDev')
        this.Test2 = new ParameterTest(true, 9, 'K_points_in_a_row_same_side')
        this.Test3 = new ParameterTest(false, 6, 'K_points_in_a_row_all_decreasing_or_increasing')
        this.Test4 = new ParameterTest(false, 14, 'K_points_in_a_row_alternating_up_and_down')
        this.Test5 = new ParameterTest(false, 2, 'K_out_of_K_plus_1_points_greater_than_2_StDev_same_side')
        this.Test6 = new ParameterTest(false, 4, 'K_out_of_K_plus_1_points_greater_than_1_StDev_same_side')
        this.Test7 = new ParameterTest(false, 15, 'K_points_in_a_row_within_1_StDev_either_side')
        this.Test8 = new ParameterTest(false, 8, 'K_points_in_a_row_greater_than_1_StDev_either_side')
    }
}
