import { FailedTestPoint } from './failed-test-point.model';
export class FailedTestReport {

    points: FailedTestPoint[];
    testPerformed: boolean;
    expanded: boolean;

    get failureCount() { return this.points.length }
    
    get pointIndexes() { return this.points.map(point => point.index); }

    constructor(points: FailedTestPoint[] = [], testPerformed: boolean = false, expanded: boolean = false) {
        this.points = points;
        this.testPerformed = testPerformed;
        this.expanded = expanded;
    }

    isPointIndexFailed(index: number): boolean {
        return this.testPerformed && this.pointIndexes.includes(index);
    }
}