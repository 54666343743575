import { LateralService } from './lateral.service';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Parameter } from '../models/parameter.model';
import { AuthService } from './auth.service';
import { TrendSettings } from '../models/trend-settings';
import { Trend } from '../models/trend.model';
import { ReportService } from './report.service';
import { ParameterApiService } from './parameter.api.service';

@Injectable()
export class TrendService extends ApiBaseService {

    constructor(
        protected http: HttpClient, 
        protected authService: AuthService,
        protected lateralService: LateralService,
        protected reportService: ReportService,
        protected parameterService: ParameterApiService
    ) { 
        super(http, authService); 
    }

    saveTrend() {
        const fields = this.lateralService.fields;
        this.parameterService.getAPIParameters().subscribe(parameters => {
            for (let field of fields) {
                if (field.parameterId) {
                    const parameter = parameters.find(parameter => parameter.ID === field.parameterId);
                    const data = this.lateralService.getData(field);

                    if (parameter) this.reportService.chart(field, parameter, data, true).subscribe();
                }
            } 
        });
    }

    getParameterTrends(parameter: Parameter): Observable<any> {
        return this.http.get<any>(this.getUrl(`/trend/parameter/${ parameter.ID }/statistics`), this.manufactingApiOptions());
    }

    getAllParameterTrends(): Observable<any> {
        return this.http.get<any>(this.getUrl(`/trend/parameters`), this.manufactingApiOptions());
    }

    setTrendSettings(settings: TrendSettings): Observable<any> {
        return this.http.put<any>(this.getUrl(`/key_trend_settings`), settings, this.manufactingApiOptions());
    }

    getTrendSettings(): Observable<any> {
        return this.http.get<any>(this.getUrl(`/key_trend_settings`), this.manufactingApiOptions());
    }

    deleteTrend(trend: Trend): Observable<any> {
        return this.http.delete<any>(this.getUrl(`/trend/parameter/${trend.parameterId}/${trend.epoch}`), this.manufactingApiOptions());
    }
}
