import { TrendService } from './../../services/trend.service';
import { Parameter } from 'src/app/models/parameter.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TrendStatisticType } from 'src/app/models/trend-statistic-type.enum';
import { TrendReport } from 'src/app/models/trend-report-model';
import { NotificationType } from 'src/app/models/notification-type.enum';
import { ParameterApiService } from 'src/app/services/parameter.api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ExportService } from 'src/app/services/export.service';
import { Trend } from 'src/app/models/trend.model';
import { TrendSettings } from 'src/app/models/trend-settings';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-key-trends',
  templateUrl: './key-trends.component.html',
  styleUrls: ['./key-trends.component.scss']
})
export class KeyTrendsComponent implements OnInit {

  @ViewChild("keyTrendContainer") keyTrendContainer;

  showKeyTrendModal: boolean = false
  editingKeyTrend: TrendReport = null
  selectedParameter: Parameter = null
  selectedStatType: TrendStatisticType = null
  showSelectParameter: boolean = false;
  showSelectStats: boolean = false;
  
  keyTrendChanged$: Subject<void> = new Subject();

  get keyTrendChangedObservable$(): Observable<void> {
    return this.keyTrendChanged$.asObservable();
  }

  // Proxy to underlying trendSettings object
  get trendReports() {
    return this.trendSettings.trendReports;
  }

  get activeParameters() {
    return this.parameters.filter(parameter => parameter.Active);
  }

  trendSettings: TrendSettings = new TrendSettings();
  trends: Trend[] = []
  parameters: Parameter[] = [];
  statTypeOptions = [];

  get allowSaveKeyTrend(): Boolean {
    return !!this.selectedParameter && !!this.selectedStatType;
  }

  constructor(
    private parameterService: ParameterApiService,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private exportService: ExportService,
    private trendService: TrendService,
  ) { }

  ngOnInit() {
    this.statTypeOptions = [
      { label: TrendStatisticType.Mean, value: TrendStatisticType.Mean },
      { label: TrendStatisticType.StandardDeviation, value: TrendStatisticType.StandardDeviation },
      { label: TrendStatisticType.MaximumValue, value: TrendStatisticType.MaximumValue },
      { label: TrendStatisticType.MinimumValue, value: TrendStatisticType.MinimumValue },
      { label: TrendStatisticType.CpK, value: TrendStatisticType.CpK },
      { label: TrendStatisticType.Cp, value: TrendStatisticType.Cp },
      { label: TrendStatisticType.Cpm, value: TrendStatisticType.Cpm },
      { label: TrendStatisticType.PpK, value: TrendStatisticType.PpK },
      { label: TrendStatisticType.Pp, value: TrendStatisticType.Pp },
    ];

    this.loadingService.startLoading('Loading Trend Data');
    this.parameterService.getAPIParameters().subscribe(
      parameters => {
        this.parameters = parameters.filter(parameter => parameter.Active);

        this.trendService.getAllParameterTrends().subscribe(
          (parameterTrends) => {
          
            this.trends = [];

            parameterTrends.forEach(paramTrend => {
              const mappedParameterTrends = paramTrend.Trends.map(trend => {
                return new Trend(
                  paramTrend.ID,
                  trend['Cp'],
                  trend['Cpk'],
                  trend['Cpm'],
                  trend['Maximum Value'],
                  trend['Mean'],
                  trend['Minimum Value'],
                  trend['Pp'],
                  trend['Ppk'],
                  trend['Specifications']['LSL'],
                  trend['Specifications']['Target'],
                  trend['Specifications']['USL'],
                  trend['Standard Deviation'],
                  trend['Timestamp'],
                  trend['Epoch'],
                  trend['Data Name']
                );
              });

              this.trends = this.trends.concat(mappedParameterTrends);
            })

            this.trendService.getTrendSettings().subscribe(
              trendSettings => {
                if (trendSettings) {
                  this.trendSettings = new TrendSettings(
                    trendSettings.ID,
                    trendSettings.trendReports ? trendSettings.trendReports : []
                  );
                }

                if (this.trendSettings.trendReports) {
                  this.trendSettings.trendReports = this.trendSettings.trendReports.filter(trendReport => {
                    return this.activeParameters.find(parameter => parameter.ID == trendReport.parameterId);
                  });
                }
              },
              error => {
                this.loadingService.stopLoading();
                this.notificationService.notify(error, NotificationType.DANGER)
              },
              () => {
                this.loadingService.stopLoading();
              }
            )
          },
          (error) => {
            this.loadingService.stopLoading();
            this.notificationService.notify(error, NotificationType.DANGER)
          }
        )
      },
      error => {
        this.loadingService.stopLoading();
        this.notificationService.notify(error, NotificationType.DANGER);
      }
    )
  }

  showPrompt() {
    this.showKeyTrendModal = true;
    this.showSelectParameter = true;
    this.showSelectStats = false;
  }

  dismissPrompt() {
    this.showKeyTrendModal = false;
    this.showSelectParameter = false;
    this.showSelectStats = false;

    this.editingKeyTrend = null;
    this.selectedParameter = null;
    this.selectedStatType = null;
  }

  saveKeyTrend(report: TrendReport) {
    if (this.editingKeyTrend) {
      this.editingKeyTrend.parameterId = this.selectedParameter.ID;
      this.editingKeyTrend.trendStatisticsType = this.selectedStatType;
    } else {
      if (!report) {
        report = new TrendReport(
          this.selectedParameter.ID,
          this.selectedStatType
        );
      }

      this.trendReports.push(report);
    }

    this.setKeyTrendSettings("Trend report saved successfully")

    if (this.showKeyTrendModal) this.dismissPrompt();
  }

  setKeyTrendSettings(message: string = null) {
    this.loadingService.startLoading("Saving trend reports");
    
    this.keyTrendChanged$.next();
    this.trendService.setTrendSettings(this.trendSettings).subscribe(
      () => {
        if (message) {
          this.notificationService.notify(message, NotificationType.SUCCESS);
        }
      },
      (error) => {
        this.loadingService.stopLoading();
        this.notificationService.notify(error, NotificationType.DANGER);
      },
      () => {
        this.loadingService.stopLoading();
      }
    )
  }

  export() {
    this.loadingService.startLoading('Exporting to Microsoft Word');

    const panels = this.keyTrendContainer.nativeElement.querySelectorAll('.report-panel');

    this.exportService.exportToWord(panels)
      .catch(error => {
        this.loadingService.stopLoading();
        this.notificationService.notify(error, NotificationType.DANGER);
      }).then(() => {
        this.loadingService.stopLoading();
      });
  }

  selectParameter(parameter: Parameter) {
    this.selectedParameter = parameter;
  }

  confirmSelectParameter() {
    this.showSelectParameter = false;
    this.showSelectStats = true;
  }

  selectStatType(type: TrendStatisticType) {
    this.selectedStatType = type;
  }

  moveUpReport(report: TrendReport) {
    const index = this.trendReports.indexOf(report);

    // Can only move up if not already at beginning
    if (index > 0) {
      // Remove item from the array
      this.trendReports.splice(index, 1);

      // Reinsert in front of last position
      this.trendReports.splice(index - 1, 0, report);
    }

    this.setKeyTrendSettings()
  }

  moveDownReport(report: TrendReport) {
    const index = this.trendReports.indexOf(report);

    // Can only move down if not already at end
    if (index < this.trendReports.length - 1) {
      // Remove item from the array
      this.trendReports.splice(index, 1);

      // Reinsert in behind last position
      this.trendReports.splice(index + 1, 0, report);
    }

    this.setKeyTrendSettings()
  }

  editReport(report: TrendReport) {
    this.editingKeyTrend = report;
    this.selectedParameter = this.parameters.find(parameter => parameter.ID == report.parameterId)
    this.selectedStatType = report.trendStatisticsType;

    this.showPrompt();
  }

  removeReport(report: TrendReport) {
    this.trendReports.splice(this.trendReports.indexOf(report), 1);

    this.setKeyTrendSettings("Trend report successfully deleted")
  }

  title(report: TrendReport) {
    const parameter = this.parameters.find(parameter => parameter.ID == report.parameterId);

    return `${parameter.Name} ${report.trendStatisticsType }`;
  }
}
