import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationType } from "src/app/models/notification-type.enum";
import { AdminApiService } from "src/app/services/admin.api.service";
import { NotificationService } from "src/app/services/notification.service";
import { AuthService } from "../../services/auth.service";
import { ParameterApiService } from "../../services/parameter.api.service";
import { environment } from "./../../../environments/environment";
@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private parameterApiService: ParameterApiService,
    private notificationService: NotificationService,
    private adminService: AdminApiService
  ) {}
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  password: string;
  promo: string;
  reEnterPassword: string;
  showModal: boolean = false;
  agreeToTerms: boolean = false;
  ngOnInit() {}
  signUp() {
    let valid = true;
    let message = "";
    if (this.password && this.password !== this.reEnterPassword) {
      message += "Make sure both passwords match.";
      valid = false;
    } else if (!this.agreeToTerms) {
      message += "You must agree to the terms and condtions to continue.";
      valid = false;
    }

    if (valid) {
      this.submit();
    } else {
      this.notificationService.notify(message, NotificationType.DANGER);
    }
  }
  submit() {
    this.adminService
      .createUserAnon(
        this.firstName,
        this.lastName,
        this.company,
        this.email,
        this.promo,
        this.password,
        false,
        true
      )
      .then(() => {
        console.log("navigate to upload");

        if (environment.production) {
          (<any>window).dataLayer.push({ event: "sign-up" });
        }

        this.router.navigate(["/confirmation"]);
      });
  }
}
