import { TrendStatisticType } from './trend-statistic-type.enum';
export class TrendReport {

    /**
     * For saving trends we save just the spec limit ID, but if we want
     * to preserve the state that the spec limit was in at the time the 
     * trend was saved we need to save the values of the spec limit for 
     * each trend.
     * 
     * This may change in the future, which is why this comment is here
     * as a reminder.
     */

    parameterId: string;
    trendStatisticsType: TrendStatisticType;

    constructor(
        parameterId: string = null,
        trendStatisticsType: TrendStatisticType = null
    ) {
        this.parameterId = parameterId;
        this.trendStatisticsType = trendStatisticsType;
    }
}