import { LateralService } from './../../services/lateral.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrendService } from 'src/app/services/trend.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  
  constructor(
    private router: Router,
    private trendService: TrendService,
    private lateralService: LateralService
  ) { }

  route(path: string) {

    if (this.router.url.includes('analyze') && path === '/report') {
      this.lateralService.clearReports();
      this.trendService.saveTrend();
    }

    this.router.navigate([path]);
  }
  get activeRoute(){
    return this.router.url;
  }
}
