import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { AuthService } from './auth.service';
import { UserProfile } from '../models/profile.model';

@Injectable()
export class ProfileApiService extends ApiBaseService {

    constructor(protected http: HttpClient, protected authService: AuthService) {
        super(http, authService);
    }
          
    getProfile(): Observable<UserProfile> {             
        return this.http.get<UserProfile>(this.getUrl(`/profile`), this.manufactingApiOptions());
    }
    setProfile(profile:UserProfile, token?:string): Observable<UserProfile> {             
        return this.http.put<UserProfile>(this.getUrl(`/profile`), profile, this.manufactingApiOptions(token));
    }
    
}