import { Component, OnInit, Input } from '@angular/core';
import { Parameter } from 'src/app/models/parameter.model';
import { FailedTestReports } from 'src/app/models/failed-test-reports.model';
import { LateralService } from 'src/app/services/lateral.service';
import { ReportService } from 'src/app/services/report.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SPCReport } from 'src/app/models/spc-report.model';
import { NotificationType } from 'src/app/models/notification-type.enum';
import { Field } from 'src/app/models/field.model';
import { RangeReport } from 'src/app/models/range-report.model';
import { FailedTestReport } from 'src/app/models/failed-test-report.model';

@Component({
  selector: 'app-failed-tests',
  templateUrl: './failed-tests.component.html',
  styleUrls: ['./failed-tests.component.scss']
})
export class FailedTestsComponent implements OnInit {

  @Input() field: Field;

  isLoading: boolean;
  data: number[];
  parameter: Parameter;
  failedTestReports: FailedTestReports

  constructor(
    private lateralService: LateralService,
    private reportService: ReportService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    try {
      this.parameter = this.lateralService.getParameter(this.field);
      this.data = this.lateralService.getData(this.field);

      this.isLoading = true;
      this.reportService.chart(this.field, this.parameter, this.data).subscribe(
        report => {
          this.failedTestReports = new FailedTestReports(
            new SPCReport(report['Statistics'][0], this.parameter.Tests), 
            new RangeReport(report['Statistics'][0], this.parameter.Tests)
          );
        }, 
        error => { this.notificationService.notify(error, NotificationType.DANGER); }, 
        () => { this.isLoading = false; }
      );
    } catch(error) {
      this.notificationService.notify(error, NotificationType.DANGER);
    }
  }

  toggleExpanded(failedTestReport: FailedTestReport) {
    failedTestReport.expanded = !failedTestReport.expanded;
  }
}
