import { User } from './../models/user.model';
import { Injectable } from "@angular/core";

import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { NotificationType } from '../models/notification-type.enum';
import { NotificationService } from './notification.service';
import { LateralService } from './lateral.service';

@Injectable()
export class AuthService {

  public currentUser: User;

  constructor(public afAuth: AngularFireAuth, private notificationService: NotificationService, private lateralService: LateralService) { }

  doEmailLogin(email: string, password: string) {
    var self = this;
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password).then(result => {
        resolve(result);
      }

      )
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode === 'auth/wrong-password') {

            self.notificationService.notify('Incorrect password.', NotificationType.DANGER);
          }
          else {

            self.notificationService.notify(errorMessage, NotificationType.DANGER);

          }
          console.log(error);
        });
    });
  }

  doGoogleLogin() {
    let isCordovaApp = document.URL.indexOf('http://') === -1
      && document.URL.indexOf('https://') === -1;

    if (isCordovaApp) {
      return new Promise<any>((resolve, reject) => {
        let provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');

        firebase.auth().signInWithRedirect(provider).then(function () {

          return firebase.auth().getRedirectResult();
        }).then(function (result) {
          // This gives you a Google Access Token.
          // You can use it to access the Google API.
          //alert(result)
          /* var token = result.credential.accessToken;
          // The signed-in user info.
          var user = result.user.; */
          resolve(result);
          // ...
        }).catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
        });
      });
    }
    else {
      return new Promise<any>((resolve, reject) => {
        let provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        this.afAuth.auth
          .signInWithPopup(provider)
          .then(res => {
            resolve(res);
          }, err => {
            console.log(err);
            reject(err);
          })
      })
    }
  }

  Logout() {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.afAuth.auth.signOut()
        this.lateralService.clearData();
        this.currentUser = null;

        resolve();
      }
      else {
        reject();
      }
    });
  }

  isUserLoggedIn(): Boolean {
    return !!this.currentUser;
  }
  forgotPassword(email) {
    var self = this;
    return new Promise((resolve, reject) => {
      firebase.auth().sendPasswordResetEmail(email)
        .then(() => {
          console.log("email sent");
          resolve();
        })
        .catch((error) => {
          self.notificationService.notify(error.message, NotificationType.DANGER);
        })
    });

  }
  fetchSignInMethodsForEmail(email) {
    var self = this;
    return new Promise((resolve, reject) => {
      firebase.auth().fetchSignInMethodsForEmail(email)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          self.notificationService.notify(error.message, NotificationType.DANGER);
        })
    });

  }
  

}
