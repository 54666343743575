import { Injectable } from "@angular/core";
import { NotificationType } from "../models/notification-type.enum";
import { Notification } from "../models/notification.model";

@Injectable()
export class NotificationService {

    public notifications: Notification[] = [];
    
    constructor() {}

    notify(
      message: string = '',
      type: string = NotificationType.DEFAULT,
      duration: number = 4000,
      dismissible: boolean = true
    ) {
      this.notifications.push(new Notification(message, type, duration, dismissible, this));
    }

    dismiss(notification: Notification) {
      this.notifications.splice(this.notifications.indexOf(notification), 1);
    }

    dismissAll() {
      this.notifications = [];
    }
}
