import { Component, OnInit } from '@angular/core';
import { LateralService } from '../../services/lateral.service';
import { ParameterApiService } from '../../services/parameter.api.service';
import { Parameter } from '../../models/parameter.model';
import { Router } from '../../../../node_modules/@angular/router';
import { NotificationService } from '../../services/notification.service';
import { LoadingService } from '../../services/loading.service';
import { NotificationType } from '../../models/notification-type.enum';
import { ReportService } from 'src/app/services/report.service';
import { Field } from 'src/app/models/field.model';
import { TrendService } from 'src/app/services/trend.service';

@Component({
  selector: 'app-analyze',
  templateUrl: './analyze.component.html',
  styleUrls: ['./analyze.component.scss']
})
export class AnalyzeComponent implements OnInit {
  csvData: any[];
  csvFields: Field[];
  parameters: Parameter[];
  showModal: boolean = false;
  modalId: number;

  //if at least one parameter is mapped, we allow progess
  get canProgress() {
    for (let field of this.csvFields) {
      if (field.parameterId) {
        return true;
      }
    }

    return false;
  }
  constructor(
    private lateralService: LateralService, 
    private parameterService:ParameterApiService,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private router: Router,
    private trendService: TrendService
  ) { }

  ngOnInit() {
    this.checkState();
    this.csvData = this.lateralService.data;
    this.csvFields = this.lateralService.fields;

    this.loadingService.startLoading('Loading Spec Limits');
    this.parameterService.getAPIParameters().subscribe(parameters => { 
        this.parameters = parameters 
      },
      (error) => {
        this.loadingService.stopLoading();
        this.notificationService.notify(error, NotificationType.DANGER);
      },
      () => { 
        this.loadingService.stopLoading();
      }
    );

  }
  openModal(id:number){
    this.showModal = true;
    this.modalId = id;

  }
  closeModal(){
    this.showModal = false;
  }
  showSpecLimitsModal = false;
  toggleSpecLimitsModal(value){
    this.showSpecLimitsModal = value;
  }

  specLimitsClosed(){
    
    this.parameterService.getAPIParameters().subscribe(parameters => { 
      this.parameters = parameters 
      this.toggleSpecLimitsModal(false);
    },
    (error) => {
      this.loadingService.stopLoading();
      this.notificationService.notify(error, NotificationType.DANGER);
      this.toggleSpecLimitsModal(false);
    },
    () => { 
      this.loadingService.stopLoading();
      this.toggleSpecLimitsModal(false);
    }
  );
  }

  toReport() {
    this.beforeNavToReport();
    this.router.navigate(['/report']);
  }

  beforeNavToReport() {
    this.lateralService.clearReports();
    this.trendService.saveTrend();
  }

  selectParameter(param : Parameter){
    let index = this.csvFields.findIndex(x=>x.id == this.modalId);
    if(index != -1){
      this.csvFields[index].mappedParameter = param.Name;
      this.csvFields[index].parameterId = param.ID;
    }
    
    this.closeModal();
  }
 
  checkState(){
    //if the fields and data are not set, then we navigated directly to this page.
    //send them to the upload page instead
    if(!this.lateralService.fields || !this.lateralService.data){
      this.router.navigate(['/upload']);
    }
  }
  clearFieldMapping(fieldId: number){
    
    let fieldIndex = this.csvFields.findIndex(x=>x.id === fieldId);
    this.csvFields[fieldIndex].mappedParameter = null;
    this.csvFields[fieldIndex].parameterId = null;

  }
}
