import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { NotificationService } from "src/app/services/notification.service";
import { AuthService } from "../services/auth.service";
import { User } from "./../models/user.model";
import { ConfirmationService } from "./../services/confirmation.service";
import { LoadingService } from "./../services/loading.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  get currentUser(): User {
    return this.authService.currentUser;
  }

  get isUserLoggedIn(): Boolean {
    return this.authService.isUserLoggedIn();
  }

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private loadingService: LoadingService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    // Whenever router executes a navigation:
    // Dismiss notifications, Stop loading, and reject any outstanding confirmations
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.notificationService.dismissAll();
        this.loadingService.stopLoading();
        this.confirmationService.rejectConfirmation(
          "Navigated away from confirmation."
        );
      }
    });
  }

  ngOnInit() {
    /* of(this.isUserLoggedIn).subscribe(isUserLoggedIn => {
      
      if (!isUserLoggedIn) this.router.navigate(['/login']);
    }); */
  }
}
