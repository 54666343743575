export class ControlLimitSegment {

    value: number
    startIdx: number
    endIdx: number

    constructor(
        value: number,
        startIdx: number,
        endIdx: number
    ) {
        this.value = value;
        this.startIdx = startIdx;
        this.endIdx = endIdx;
    }
}