export class Trend {

    parameterId: string
    cp: number
    cpk: number
    cpm: number
    maximumValue: number
    mean: number
    minimumValue: number
    pp: number
    ppk: number
    lsl: number
    target: number
    usl: number
    standardDeviation: number
    timestamp: string
    epoch: number
    columnName: string

    constructor(
        parameterId: string,
        cp: number,
        cpk: number,
        cpm: number,
        maximumValue: number,
        mean: number,
        minimumValue: number,
        pp: number,
        ppk: number,
        lsl: number,
        target: number,
        usl: number,
        standardDeviation: number,
        timestamp: string,
        epoch: number,
        columnName: string
    ) {
        this.parameterId = parameterId;
        this.cp = cp;
        this.cpk = cpk;
        this.cpm = cpm;
        this.maximumValue = maximumValue;
        this.mean = mean;
        this.minimumValue = minimumValue;
        this.pp = pp;
        this.ppk = ppk;
        this.lsl = lsl;
        this.target = target;
        this.usl = usl;
        this.standardDeviation = standardDeviation;
        this.timestamp = timestamp;
        this.epoch = epoch;
        this.columnName = columnName;
    }
}