import { Font } from "plotly.js";

// export const DEFAULT_REPORT_WIDTH = 458;
// export const DEFAULT_REPORT_HEIGHT = 314 // (report height) = (total height) - (header height + padding + border);
// export const DEFAULT_REPORT_MARGIN = 50;
export const DEFAULT_REPORT_WIDTH = 900;
export const DEFAULT_REPORT_HEIGHT = 600 
export const DEFAULT_REPORT_MARGIN = 100;
export const DEFAULT_REPORT_PADDING = 0;
export const DEFAULT_REPORT_TICKCOUNT = 10;
export const DEFAULT_REPORT_TICKFONT: Partial<Font> = {
    family: 'Open Sans, monospace',
    size: 14,
    color: '#000'
}
export const DEFAULT_REPORT_AXISTITLEFONT: Partial<Font> = {
    family: 'Open Sans, monospace',
    size: 16,
    color: '#000'
}
export const DEFAULT_REPORT_TITLEFONT: Partial<Font> = {
    family: 'Open Sans, monospace',
    size: 18,
    color: '#000'
}

// export const MOBILE_REPORT_WIDTH = 278;
// export const MOBILE_REPORT_HEIGHT = 179 // (report height) = (total height) - (header height + padding + border);
// export const MOBILE_REPORT_MARGIN = 25;
export const MOBILE_REPORT_WIDTH = 317;
export const MOBILE_REPORT_HEIGHT = 180;
export const MOBILE_REPORT_MARGIN = 25;
export const MOBILE_REPORT_PADDING = 0;
export const MOBILE_REPORT_TICKCOUNT = 6;
export const MOBILE_REPORT_TICKFONT: Partial<Font> = {
    family: 'Open Sans, monospace',
    size: 6,
    color: '#000'
}
export const MOBILE_REPORT_AXISTITLEFONT: Partial<Font> = {
    family: 'Open Sans, monospace',
    size: 8,
    color: '#000'
}
export const MOBILE_REPORT_TITLEFONT: Partial<Font> = {
    family: 'Open Sans, monospace',
    size: 10,
    color: '#000'
}