import {AppConstants} from "../utils/app.contants";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { AuthService } from "./auth.service";

export abstract class ApiBaseService {

  constructor(protected http: HttpClient, protected authService: AuthService ) {
      this.baseUrl = AppConstants.api.baseUrl;
  }

  private baseUrl: string;

  protected getUrl(url: any):string {
      return this.baseUrl + url;
  }

  //call this on any api call to manufacting api
  protected manufactingApiOptions(token? : string, userToken?: boolean){
    let bearerToken = (token)?token:this.authService.currentUser.token;
    return (userToken)?{
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + bearerToken,
        "Token":"4E272A0D-847B-46CD-9CA4-FDC8B0119DE1"
      })
  } : {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + bearerToken
    })
}; 
  }
}
