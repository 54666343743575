import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-report-panel',
  templateUrl: './report-panel.component.html',
  styleUrls: ['./report-panel.component.scss']
})
export class ReportPanelComponent {

  @Input() id: string = '';
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() showMenu: boolean = true;

  @Output() remove: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() moveUp: EventEmitter<any> = new EventEmitter();
  @Output() moveDown: EventEmitter<any> = new EventEmitter();

  isMenuShowing = false;

  private MAX_TITLE_LENGTH = 50;

  get formattedTitle() {    
    return this.title.length > this.MAX_TITLE_LENGTH ? 
        `${this.title.slice(0, this.MAX_TITLE_LENGTH)}...` :
        this.title;
  }

  get formattedSubTitle() {    
    return this.subtitle.length > this.MAX_TITLE_LENGTH ? 
        `${this.subtitle.slice(0, this.MAX_TITLE_LENGTH)}...` :
        this.subtitle;
  }

  constructor() { }

  _edit() {
    this.edit.emit();
  }

  _remove() {
    this.remove.emit();
  }

  _moveUp() {
    this.moveUp.emit();
  }

  _moveDown() {
    this.moveDown.emit();
  }

  _showMenu() {
    if (this.showMenu) this.isMenuShowing = true
  }

  _hideMenu() {
    if (this.showMenu) this.isMenuShowing = false;
  }
}
