import { ReportType } from './report-type.enum';
import { Field } from './field.model';

export class Report {
    type: ReportType
    field: Field

    constructor(
        type: ReportType = null,
        field: Field = null
    ) {
        this.type = type;
        this.field = field;
    }
}