import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AdminApiService } from 'src/app/services/admin.api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationType } from 'src/app/models/notification-type.enum';
import { User } from 'src/app/models/user.model';
import { PapaParseService } from 'ngx-papaparse';
import { Parameter } from 'src/app/models/parameter.model';
import { ParameterApiService } from 'src/app/services/parameter.api.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private authService: AuthService, private adminService: AdminApiService, private notificationService: NotificationService, private papa: PapaParseService, private parameterService: ParameterApiService) { }

  users:any[] = [];
  newEmail:string = "";
  newPassword:string = "";
  newName:string = "";
  newIsAdmin:  boolean;

  ngOnInit() {
    this.loadUsers();
  }

  loadUsers(){
    this.users = [];
    this.adminService.getAllUsers().subscribe(x=>{
      let keys = Object.keys(x.users);
      for(let key of keys){
        if(x.users[key].uuid){
          let user = {
            email: key,
            name: x.users[key].name,
            uuid: x.users[key].uuid,
            access:x.users[key].access,
            license: x.users[key].license
          }
          this.users.push(user);
        }

      }
    })
  }
  createUser(){
    this.newEmail = this.newEmail.toLowerCase();
   this.adminService.createUser(this.newName, this.newEmail, this.newPassword, this.newIsAdmin, false).then(result=>{
    this.newName = "";
    this.newEmail = "";
    this.newPassword = "";
    this.newIsAdmin = false;
    this.notificationService.notify("User Created", NotificationType.SUCCESS);
    this.loadUsers();
    
   });
  }
  resetPassword(email){

    this.authService.forgotPassword(email).then(result=>{
     this.notificationService.notify("Password Reset Sent", NotificationType.INFO);
    });
   }
   saveUser(user:any){      
      this.adminService.setUser(user).subscribe(); 
   }
   uploadParameters(fileEvent, uuid){
    this.papa.parse(
      fileEvent.files[0], 
      {
      complete: results => {
        console.log(results);  
        let parameters = [];
        
        this.parameterService.getUserParameters(uuid).subscribe(x=>{
          parameters = x.concat(this.getParamsFromFileObject(results));;
          this.parameterService.setUserParameters(parameters, uuid).subscribe(x=>{
            this.notificationService.notify("Spec Limits Uploaded", NotificationType.SUCCESS);
          })
        });
      },
      header: true,
      dynamicTyping: true
    });
    fileEvent.value = '';
  }
  getParamsFromFileObject(parsedFile) : Parameter[] {
    let parameters: Parameter[] = [];
    for(let item of parsedFile.data){
      let param = new Parameter();

      param.Description = item["Item Number"];
      param.Limit1 = item["upper limit"];
      param.Limit2 = item["lower limit"];
      param.MachineName = item["Template Name"];
      param.Name = item["Item Name"] + " " + item["Characteristic"];
      param.SubGroupSize = item["subgroup"];
      param.Target = item["target"];
      param.Units = item["units"];
      param.Upper = true;
      param.Active = true;
      param.SaveTrend = true;

      parameters.push(param);
    }
    return parameters;
  }
}
