import {Injectable} from "@angular/core";
import { Field } from "../models/field.model";
import { ReportDefaults } from "../models/report-defaults.model";
import { Parameter } from "../models/parameter.model";
import { Report } from "../models/report.model";

@Injectable()
export class LateralService {

    public data: any[];
    //public xlsxData: any[];    
    public fields: Array<Field> = [];
    //public xlsxFields: Array<Field> = [];
    public reports: Array<Report> = [];
    public reportDefaults: ReportDefaults;
    public parameters: Parameter[] = [];
    public fileName: string;
    constructor() {}

    clearReports() {
      this.reports = [];
    }

    getParameter(field: Field) {
      return this.parameters.find(parameter => parameter.ID == field.parameterId);
    }

    getData(field: Field) {
      return this.data.map(data => {
        return data[field.name] != null ? data[field.name] : null;
      });
    }
    clearData(){
        this.data = null;
        this.fields = [];
        this.reports = [];
        this.reportDefaults = null;
        this.parameters = [];
        this.fileName = null;
    }
}
