import { ParameterTests } from './parameter-tests.model';

export class SPCReport {

    values: number[]
    lowerControlLimits: number[]
    XBar: number
    upperControlLimits: number[]
    lowerSpec: number
    upperSpec: number
    failedTest1: number[]
    failedTest2: number[]
    failedTest3: number[]
    failedTest4: number[]
    failedTest5: number[]
    failedTest6: number[]
    failedTest7: number[]
    failedTest8: number[]

    performedTest1: boolean = false;
    performedTest2: boolean = false;
    performedTest3: boolean = false;
    performedTest4: boolean = false;
    performedTest5: boolean = false;
    performedTest6: boolean = false;
    performedTest7: boolean = false;
    performedTest8: boolean = false;

    constructor(response: object, parameterTests: ParameterTests) {
        const SPCChart = response['IChart'] || response['XbarChart'];

        const processData = response['Capability(normal)']['Process Data'];
        const tests = SPCChart['Tests']

        this.values = SPCChart['Values'];
        this.lowerControlLimits = SPCChart['LCLValues'];
        this.XBar = SPCChart['Centerline'];
        this.upperControlLimits = SPCChart['UCLValues'];
        this.lowerSpec = processData['LSL'] !== null ? Number(processData['LSL']) : null;
        this.upperSpec = processData['USL'] !== null ? Number(processData['USL']) : null;
        this.failedTest1 = tests['Test1']['Test Failed at points'];
        this.failedTest2 = tests['Test2']['Test Failed at points'];
        this.failedTest3 = tests['Test3']['Test Failed at points'];
        this.failedTest4 = tests['Test4']['Test Failed at points'];
        this.failedTest5 = tests['Test5']['Test Failed at points'];
        this.failedTest6 = tests['Test6']['Test Failed at points'];
        this.failedTest7 = tests['Test7']['Test Failed at points'];
        this.failedTest8 = tests['Test8']['Test Failed at points'];

        this.performedTest1 = parameterTests.Test1.Perform;
        this.performedTest2 = parameterTests.Test2.Perform;
        this.performedTest3 = parameterTests.Test3.Perform;
        this.performedTest4 = parameterTests.Test4.Perform;
        this.performedTest5 = parameterTests.Test5.Perform;
        this.performedTest6 = parameterTests.Test6.Perform;
        this.performedTest7 = parameterTests.Test7.Perform;
        this.performedTest8 = parameterTests.Test8.Perform;
    }
}