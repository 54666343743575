export enum COLOR {
    STEEL_BLUE = '#4682b4',
    STEEL_BLUE1 = '#63b8ff',
    STEEL_BLUE2 = '#5cacee',
    STEEL_BLUE3 = '#4f94cd',
    STEEL_BLUE4 = '#36648b',
    FIRE_BRICK = '#b22222',
    DARK_GOLDENROD1 = '#ffb90f',
    GREEN = '#23d160'
}
