import { ParameterApiService } from './parameter.api.service';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Parameter } from '../models/parameter.model';
import { AuthService } from './auth.service';
import { ChartRequest } from '../models/chart-request.model';
import { Field } from '../models/field.model';

@Injectable()
export class ReportService extends ApiBaseService {

    constructor(
        protected http: HttpClient, 
        protected authService: AuthService,
        protected parameterApiService: ParameterApiService
    ) { 
        super(http, authService); 
    }

    chart(field: Field, parameter: Parameter, data: any[], trySaveTrend = false): Observable<any> {
        return this.http.post<any>(this.getUrl('/chart'), new ChartRequest(this.parameterApiService, field, parameter, data, trySaveTrend), this.manufactingApiOptions());
    }
}
