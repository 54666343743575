// @angular
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// Libraries
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { AngularFireModule } from "angularfire2";
import { AngularFireAuthModule } from "angularfire2/auth";
import { AngularFirestoreModule } from "angularfire2/firestore";
import { FeedbackModule } from "ng-feedback";
import { FileDropModule } from "ngx-file-drop";
import { PapaParseModule } from "ngx-papaparse";
// Environment
import { environment } from "../environments/environment";
import { AdminGuard } from "./admin.guard";
// Routing
import { AppRoutingModule } from "./app-routing.module";
import { AuthGuard } from "./auth.guard";
import { AdminComponent } from "./components/admin/admin.component";
import { AnalyzeComponent } from "./components/analyze/analyze.component";
// Components
import { AppComponent } from "./components/app.component";
import { CapabilityHistogramComponent } from "./components/capability-histogram/capability-histogram.component";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationComponent } from "./components/confirmation/confirmation.component";
import { DefaultsComponent } from "./components/defaults/defaults.component";
import { FailedTestsComponent } from "./components/failed-tests/failed-tests.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { KeyTrendSettingsComponent } from "./components/key-trend-settings/key-trend-settings.component";
import { KeyTrendsChartComponent } from "./components/key-trends-chart/key-trends-chart.component";
import { KeyTrendsComponent } from "./components/key-trends/key-trends.component";
import { LoadingPanelComponent } from "./components/loading-panel/loading-panel.component";
import { LoginComponent } from "./components/login/login.component";
import { MenuComponent } from "./components/menu/menu.component";
import { NavArrowsComponent } from "./components/nav-arrows/nav-arrows.component";
import { NavComponent } from "./components/nav/nav.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { PageLoaderComponent } from "./components/page-loader/page-loader.component";
import { ParamsComponent } from "./components/params/params.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { RangeChartComponent } from "./components/range-chart/range-chart.component";
import { ReportPanelComponent } from "./components/report-panel/report-panel.component";
import { ReportComponent } from "./components/report/report.component";
import { SignupComponent } from "./components/signup/signup.component";
import { SPCChartComponent } from "./components/spc-chart/spc-chart.component";
import { StatisticsTableComponent } from "./components/statistics-table/statistics-table.component";
import { UploadComponent } from "./components/upload/upload.component";
import { AdminApiService } from "./services/admin.api.service";
import { AuthService } from "./services/auth.service";
import { ConfirmationService } from "./services/confirmation.service";
import { ControlLimitService } from "./services/control-limit.service";
// Services
import { LateralService } from "./services/lateral.service";
import { LoadingService } from "./services/loading.service";
import { NotificationService } from "./services/notification.service";
import { ParameterApiService } from "./services/parameter.api.service";
import { ProfileApiService } from "./services/profile.api.service";
import { ReportDefaultsService } from "./services/report-defaults.service";
import { ReportService } from "./services/report.service";
import { TrendService } from "./services/trend.service";
import { UserService } from "./services/user.service";
import { ReportOptionsComponent } from "./utils/report-options.component";






// Add all font awesome icons to library for global accessibility
library.add(fas, far);

@NgModule({
  declarations: [
    AppComponent,
    AnalyzeComponent,
    LoginComponent,
    MenuComponent,
    ParamsComponent,
    ReportComponent,
    DefaultsComponent,
    UploadComponent,
    CapabilityHistogramComponent,
    SPCChartComponent,
    RangeChartComponent,
    StatisticsTableComponent,
    ReportPanelComponent,
    NavComponent,
    ReportOptionsComponent,
    NotificationsComponent,
    PageLoaderComponent,
    LoadingPanelComponent,
    ConfirmationDialogComponent,
    KeyTrendsComponent,
    HeaderComponent,
    FooterComponent,
    ProfileComponent,
    KeyTrendSettingsComponent,
    NavArrowsComponent,
    FailedTestsComponent,
    AdminComponent,
    KeyTrendsChartComponent,
    SignupComponent,
    ConfirmationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatTableModule,
    FileDropModule,
    PapaParseModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    FeedbackModule,
  ],
  providers: [
    ParameterApiService,
    LateralService,
    AuthService,
    UserService,
    AuthGuard,
    AdminGuard,
    ReportService,
    ReportDefaultsService,
    NotificationService,
    LoadingService,
    ConfirmationService,
    AdminApiService,
    ProfileApiService,
    TrendService,
    ControlLimitService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
