import { ReportType } from './../models/report-type.enum';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-options',
  template: `
    <a *ngFor="let option of options"
       class="panel-block" 
       [ngClass]="{'is-active': activeType == option.type }"
       (click)="selectType(option.type)">
       {{ option.label }}
    </a>
  `,
  styles: [
    '.is-active { background: #315795; color:white; }',
    'a.panel-block.is-active:hover { background: #315795!important; color:white; }'
  ]
})
export class ReportOptionsComponent implements OnInit {

  @Input() activeType: ReportType
  @Output() reportSelected = new EventEmitter<ReportType>();

  options: any[];
  
  ngOnInit() {
    this.options = [
      { label: 'SPC Chart', type: ReportType.SPCChart },
      { label: 'Range Chart', type: ReportType.RangeChart },
      { label: 'Failed Tests', type: ReportType.FailedTests },
      { label: 'Capability Histogram', type: ReportType.CapabilityHistogram },
      { label: 'Statistics', type: ReportType.Statistics },
    ];
  }

  selectType(type: ReportType) {
    this.reportSelected.emit(type);
  }
}
