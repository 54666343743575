import { FailedTestReports } from 'src/app/models/failed-test-reports.model';
import { COLOR } from '../../models/color-constants.enum';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import * as Plotly from 'plotly.js';

import { Chart } from '../../models/chart.interface';
import { DataTools } from '../../utils/data-tools';
import {
  DEFAULT_REPORT_WIDTH,
  DEFAULT_REPORT_HEIGHT,
  DEFAULT_REPORT_MARGIN,
  DEFAULT_REPORT_PADDING,
  DEFAULT_REPORT_TICKFONT,
  DEFAULT_REPORT_TICKCOUNT,
  DEFAULT_REPORT_AXISTITLEFONT,
  MOBILE_REPORT_WIDTH,
  MOBILE_REPORT_HEIGHT,
  MOBILE_REPORT_AXISTITLEFONT,
  MOBILE_REPORT_TICKCOUNT,
  MOBILE_REPORT_TICKFONT,
  MOBILE_REPORT_MARGIN,
  MOBILE_REPORT_PADDING
} from '../../models/chart.constants';
import { Parameter } from '../../models/parameter.model';
import { LateralService } from '../../services/lateral.service';
import * as d3 from '../../utils/d3';
import { RangeReport } from 'src/app/models/range-report.model';
import { ReportService } from 'src/app/services/report.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationType } from 'src/app/models/notification-type.enum';
import { MediaType } from 'src/app/models/media-type.enum';
import { Field } from 'src/app/models/field.model';
import { ControlLimitService } from 'src/app/services/control-limit.service';

@Component({
  selector: 'app-range-chart',
  templateUrl: './range-chart.component.html',
  styleUrls: ['./range-chart.component.scss']
})
export class RangeChartComponent implements OnInit, Chart {

  @ViewChild('chart') element: ElementRef;

  @Input() field: Field;

  public isLoading = false;

  private points: Array<Plotly.Point>
  private lclPoints: Array<Plotly.Point>
  private uclPoints: Array<Plotly.Point>
  private parameter: Parameter;
  private RangeReport: RangeReport
  private mediaType: MediaType
  private yAxisTitle: string = ''
  private centerLineLabel: string = ''
  private failedTestReport: FailedTestReports

  constructor(
    private lateralService: LateralService,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private controlLimitService: ControlLimitService
  ) { }

  ngOnInit() {
    try {
      this.mediaType = MediaType.detect();
      this.parameter = this.lateralService.getParameter(this.field);
      const data = this.lateralService.getData(this.field);
    
      this.reportService.chart(this.field, this.parameter, data).subscribe(report => {
          this.RangeReport = new RangeReport(report['Statistics'][0], this.parameter.Tests);
          this.failedTestReport = new FailedTestReports(null, this.RangeReport);
          
          this.lclPoints = this.controlLimitService.toControlLimitPoints(this.RangeReport.lowerControlLimits);
          this.uclPoints = this.controlLimitService.toControlLimitPoints(this.RangeReport.upperControlLimits);
          this.points = this.toPoints(this.RangeReport.movingRange);

        if (this.parameter.SubGroupSize == 1) {
          this.yAxisTitle = `Moving Range`;
          this.centerLineLabel = 'M\u0305R\u0305';
        } else if (this.parameter.SubGroupSize >= 2 && this.parameter.SubGroupSize <= 8) {
          this.yAxisTitle = `Range`;
          this.centerLineLabel = 'R\u0305';
        } else if (this.parameter.SubGroupSize == 9) {
          this.yAxisTitle = `Standard Deviation`;
          this.centerLineLabel = 'S\u0305';
        }
          
          this.draw();
        },
        error => { this.notificationService.notify(error, NotificationType.DANGER); },
        () => { this.isLoading = false }
      );
    } catch(error) {
      this.notificationService.notify(error, NotificationType.DANGER);
    }
  }

  toPoints(data) {
    return data.map((it, idx) => {
      return {
        x: idx + 1,
        y: it,
        z: 0
      }
    });
  }

  suggestedTickFormat(values, tickCount) {
    const range = DataTools.range(
      DataTools.max(values),
      DataTools.min(values)
    );

    const step = DataTools.segment(range, tickCount);

    return `.${d3.precisionFixed(step)}f`;
  }


  draw() {
    let inPoints = [];
    let outPoints = [];

    this.points.sort((a, b) => a.x - b.x);
    this.points.forEach((point, index) => {
      if (!this.failedTestReport.pointFailedRangeTests(point.x)) {
        inPoints.push(point);
      } else {
        outPoints.push(point);
      }
    });

    const traces = [
      {
        mode: 'markers',
        name: 'Failed Tests',
        type: 'scatter',
        hoverinfo: 'all',
        y: outPoints.map(point => point.y),
        x: outPoints.map(point => point.x),
        marker: {
          color: COLOR.FIRE_BRICK,
          size: 5,
          symbol: 'square'
        },
        showlegend: false
      },
      {
        mode: 'markers',
        name: 'No Test Failures',
        type: 'scatter',
        hoverinfo: 'all',
        y: inPoints.map(point => point.y),
        x: inPoints.map(point => point.x),
        marker: {
          color: COLOR.STEEL_BLUE3,
          size: 5
        },
        showlegend: false
      },
      {
        mode: 'lines',
        hoverinfo: 'none',
        y: this.points.map(point => point.y),
        x: this.points.map(point => point.x),
        marker: {
          color: COLOR.STEEL_BLUE3,
          opacity: .75
        },
        line: {
          width: 1
        },
        showlegend: false,
        connectgaps: false
      },
      this.uclPoints ? {
        name: 'UCL',
        mode: 'lines',
        hoverinfo: 'all',
        y: this.uclPoints.map(point => point.y),
        x: this.uclPoints.map(point => point.x),
        line: {
          color: COLOR.FIRE_BRICK,
          dash: 'dash',
          width: 1
        },
        showlegend: true,
        legendgroup: 'control-limits'
      } : null,
      this.lclPoints ? {
        name: 'LCL',
        mode: 'lines',
        hoverinfo: 'all',
        y: this.lclPoints.map(point => point.y),
        x: this.lclPoints.map(point => point.x),
        line: {
          color: COLOR.FIRE_BRICK,
          dash: 'dash',
          width: 1
        },
        showlegend: true,
        legendgroup: 'control-limits'
      } : null,
      {
        name: this.centerLineLabel,
        mode: 'lines',
        hoverinfo: 'none',
        x: [0, this.points.length + 1],
        y: [this.RangeReport.MRBar, this.RangeReport.MRBar],
        line: {
          color: COLOR.STEEL_BLUE4,
          dash: 'solid',
          width: 1
        },
        showlegend: true
      },
    ].filter(trace => trace != null);

    // @ts-ignore
    Plotly.plot(this.element.nativeElement, traces, this.layout(), this.config());
  }

  layout() {
    return {
      showlegend: this.mediaType == MediaType.MOBILE ? false : true,
      autosize: true,
      width: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_WIDTH : DEFAULT_REPORT_WIDTH,
      height: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_HEIGHT : DEFAULT_REPORT_HEIGHT,
      hovermode: 'closest',
      xaxis: {
        title: 'Observation',
        titlefont: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_AXISTITLEFONT : DEFAULT_REPORT_AXISTITLEFONT,
        showline: false,
        showspikes: true,
        spikemode: "across",
        spikethickness: 1,
        spikedash: 'solid',
        nticks: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKCOUNT : DEFAULT_REPORT_TICKCOUNT,
        tickangle: -45,
        tickfont: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKFONT : DEFAULT_REPORT_TICKFONT,
        tickformat: this.suggestedTickFormat(this.points.map(it => it.x), this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKCOUNT : DEFAULT_REPORT_TICKCOUNT)
      },
      yaxis: {
        title: this.yAxisTitle,
        titlefont: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_AXISTITLEFONT : DEFAULT_REPORT_AXISTITLEFONT,
        showline: false,
        nticks: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKCOUNT : DEFAULT_REPORT_TICKCOUNT,
        tickfont: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKFONT : DEFAULT_REPORT_TICKFONT,
        tickformat: this.suggestedTickFormat(this.points.map(it => it.y), this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_TICKCOUNT : DEFAULT_REPORT_TICKCOUNT)
      },
      margin: {
        t: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_MARGIN : DEFAULT_REPORT_MARGIN,
        r: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_MARGIN : DEFAULT_REPORT_MARGIN,
        b: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_MARGIN : DEFAULT_REPORT_MARGIN,
        l: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_MARGIN : DEFAULT_REPORT_MARGIN,
        pad: this.mediaType == MediaType.MOBILE ? MOBILE_REPORT_PADDING : DEFAULT_REPORT_PADDING
      }
    }
  }

  config() {
    return { displayModeBar: true };
  }
}
