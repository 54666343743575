import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from "@angular/router";
import { AngularFireAuth } from 'angularfire2/auth';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { User } from './models/user.model';
import { AdminApiService } from './services/admin.api.service';


@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
    private authService: AuthService, 
    private adminService: AdminApiService
  ) {}

  canActivate(): Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.adminService.getUser().subscribe(x=>{
        if(x.access === "admin"){
          return resolve(true);
        }
        else{
          this.router.navigate(['/profile']);
          return resolve(true);
        }
      })
    })
  }
}
