import { ConfirmationService } from '../../services/confirmation.service';
import { Component, OnInit } from '@angular/core';
import { LateralService } from '../../services/lateral.service';
import { ReportDefaults } from '../../models/report-defaults.model';
import { ReportDefaultsService } from '../../services/report-defaults.service';
import { NotificationService } from '../../services/notification.service';
import { LoadingService } from '../../services/loading.service';
import { NotificationType } from '../../models/notification-type.enum';

@Component({
  selector: 'app-defaults',
  templateUrl: './defaults.component.html',
  styleUrls: ['./defaults.component.scss']
})
export class DefaultsComponent implements OnInit {

  public confirmSavePromise: Promise<string>

  get reportDefaults() {
    return this.lateralService.reportDefaults;
  }

  constructor(
    public lateralService: LateralService,
    public reportDefaultsService: ReportDefaultsService,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.lateralService.reportDefaults = new ReportDefaults();

    this.loadingService.startLoading('Loading Defaults');
    this.reportDefaultsService.getDefaults().subscribe(
      reportDefaults => this.lateralService.reportDefaults = new ReportDefaults(
        reportDefaults.ID,
        reportDefaults.showSPCChart || false,
        reportDefaults.showRangeChart || false,
        reportDefaults.showFailedTests || false,
        reportDefaults.showCapabilityHistogram || false,
        reportDefaults.showStatistics || false
      ),
      error => {
        this.loadingService.stopLoading();
        this.notificationService.notify(error, NotificationType.DANGER);
      },
      () => { 
        this.loadingService.stopLoading();
      }
    )
  }

  toggleType(type: boolean){
    type = !type;
  }

  confirmSave() {
    this.confirmSavePromise = this.confirmationService.confirm('You are about to save report defaults.')

    this.confirmSavePromise.then(() => {
        this.saveDefaults();
      }, 
      () => {

      }
    );
  }

  saveDefaults(){
    this.loadingService.startLoading('Saving Defaults');
    this.reportDefaultsService.setDefaults(this.lateralService.reportDefaults).subscribe(
      () => {
        this.notificationService.notify('Defaults Saved', NotificationType.SUCCESS);
      },
      error => {
        this.loadingService.stopLoading();
        this.notificationService.notify(error, NotificationType.DANGER);
      },
      () => { 
        this.loadingService.stopLoading();
      }
    )
  }

}
