import jStat from 'jStat';

export class DataTools {
    static max(data: Array<number>): number {
        return Math.max(...data);
    }

    static mean(data: Array<number>): number {
        return this.sum(data) / data.length;
    }

    // The median of a set of data is the middlemost number in the set. 
    // The median is also the number that is halfway into the set.
    // To find the median, the data should first be arranged in order from least to greatest.
    static median(data: Array<number>): number {
        return jStat.median(data);
    }

    static min(data: Array<number>): number {
        return Math.min(...data);
    }

    static normalPDF(x, mean, stdev, step, size): number {
        return jStat.normal.pdf(x, mean, stdev) * (size * step);
    }

    static range(min: number, max: number): number {
        return max - min;
    }

    static segment(range: number, steps: number): number {
        return range / steps;
    }

    static sum(data: Array<number>): number {
        return data.reduce((sum, val) => sum + val, 0);
    }

    static stdev(data: Array<number>): number {
        return jStat(data).stdev();
    }
}