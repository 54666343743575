import { LoadingService } from '../../services/loading.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent implements OnInit {

  get isLoading() {
   // this.cdRef.detectChanges();
    return this.loadingService.isLoading    
  }

  get message() {
    return this.loadingService.message;
  }

  constructor(private loadingService: LoadingService) { }

  ngOnInit() {
  }

}
