import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { AuthService } from './auth.service';
import { ReportDefaults } from '../models/report-defaults.model';

@Injectable()
export class ReportDefaultsService extends ApiBaseService {

    constructor(protected http: HttpClient, protected authService: AuthService) {
        super(http, authService);
    }

    getDefaults(): Observable<ReportDefaults> {
        return this.http.get<ReportDefaults>(this.getUrl('/report_layout'), this.manufactingApiOptions());
    }

    setDefaults(reportDefaults: ReportDefaults, token?:string): Observable<any> {
        return this.http.put(this.getUrl('/report_layout'), reportDefaults, this.manufactingApiOptions(token));
    }
}