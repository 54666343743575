import { ParameterTests } from './../models/parameter-tests.model';
import { ParameterTest } from './../models/parameter-test.model';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Parameter } from '../models/parameter.model';
import { identifierModuleUrl } from '@angular/compiler';
import { AuthService } from './auth.service';

@Injectable()
export class ParameterApiService extends ApiBaseService {

    constructor(protected http: HttpClient, protected authService: AuthService) {
        super(http, authService);
    }
          
    getAPIParameters(): Observable<Array<Parameter>> {             
        return this.http.get<Array<Parameter>>(this.getUrl(`/parameters`), this.manufactingApiOptions());
    }
    getUserParameters(userId): Observable<Array<Parameter>> {             
        return this.http.get<Array<Parameter>>(this.getUrl('/parameters/' + userId), this.manufactingApiOptions());
    }
    setAPIParameters(parameters: Parameter[]): Observable<any> {
        return this.http.put<any>(this.getUrl('/parameters'), parameters, this.manufactingApiOptions());
    }
    setUserParameters(parameters: Parameter[], userId): Observable<any> {
        return this.http.put<any>(this.getUrl('/parameters/' + userId), parameters, this.manufactingApiOptions());
    }
    prepareTest(test: ParameterTest) {
        const o = { };

        o["Plot"] = test.Perform;
        o[test.key] = test.K != null ? Number(test.K) : null;

        return o;
    }

    prepareTests(tests: ParameterTests) {
        return {
            Test1: this.prepareTest(tests.Test1),
            Test2: this.prepareTest(tests.Test2),
            Test3: this.prepareTest(tests.Test3),
            Test4: this.prepareTest(tests.Test4),
            Test5: this.prepareTest(tests.Test5),
            Test6: this.prepareTest(tests.Test6),
            Test7: this.prepareTest(tests.Test7),
            Test8: this.prepareTest(tests.Test8)
        }
    }
}