import { Parameter } from 'src/app/models/parameter.model';
import { Trend } from './trend.model';

export class ParameterTrendPair {

    parameter: Parameter
    trend: Trend

    constructor(
        parameter: Parameter,
        trend: Trend
    ) {
        this.parameter = parameter;
        this.trend = trend;
    }
}